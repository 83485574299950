const Documents = () =>
  import(/* webpackChunkName: "component_documents" */ 'client/components/Documents/Documents')
const DocumentSearch = () =>
  import(
    /* webpackChunkName: "component_document_search" */ 'client/components/Documents/DocumentSearch'
  )
const DocumentEdit = () =>
  import(
    /* webpackChunkName: "component_document_edit" */ 'client/components/Documents/DocumentEdit'
  )

const routes = [
  // Document Path
  {
    path: '/documents',
    component: Documents,
    name: 'documents-init',
  },
  {
    path: '/documents/search/',
    component: DocumentSearch,
    name: 'document-search',
    props: route => ({ search: route.query.s }),
    meta: {
      title: '문서 검색',
    },
  },
  {
    path: '/documents/:document',
    component: Documents,
    name: 'document',
  },
  {
    path: '/documents/:document/:version/edit',
    component: DocumentEdit,
    name: 'document-edit',
  },

  // Legacy Document Redirects
  {
    path: '/documents/(tech|policy)',
    redirect: '/documents',
  },
  {
    path: '/documents/(tech|policy)/search',
    redirect: '/documents/search',
  },
  {
    path: '/documents/(tech|policy)/:document',
    redirect: '/documents/:document',
  },
  {
    path: '/documents/(tech|policy)/:document/:version/edit',
    redirect: '/documents/:document/:version/edit',
  },
]

export default routes
