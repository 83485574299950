import Vue from 'vue'
import Vuex from 'vuex'
import games from './games'
import builds from './builds'
import resources from './resources'
import releases from './releases'
import endpoints from './endpoints'
import createBox from './createBox'
import viewModes from './viewModes'
import user from './user'
import users from './users'
import serverfarms from './serverfarms'
import documents from './documents'
import notices from './notices'
import updates from './updates'
import environments from './environments'
import apiVersion from './apiVersion'
import wordfilter from './wordfilter'
import coupons from './coupons'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    games,
    builds,
    resources,
    releases,
    endpoints,
    createBox,
    user,
    users,
    viewModes,
    serverfarms,
    documents,
    notices,
    updates,
    environments,
    apiVersion,
    wordfilter,
    coupons,
  },
  getters: {
    gameCode(state) {
      const routeGameCode = state.route.params.gameCode
      return routeGameCode ? routeGameCode : state.games.gameCode
    },
  },
})
