import * as api from '../api'
import Vue from 'vue'
import { BuildStatus } from '../utils'

export default {
  state: {
    builds: {},
    buildsNextUrl: {
      ios: {},
      android: {},
    },
  },
  getters: {
    buildsByGameId: state => gameId =>
      Object.values(state.builds[gameId] || {}).sort((a, b) => b.id - a.id),
    buildById: state => (gameId, buildId) => state.builds[gameId][buildId] || {},
  },
  actions: {
    async getBuild({ commit }, { buildId }) {
      const build = await api.getBuild(buildId)
      const gameId = build.game
      commit('upsertBuilds', { gameId, builds: [build] })
      return build
    },
    async getBuildsForGame(
      context,
      { gameId, platform, type = null, store = null, search = null },
    ) {
      context.commit('clearBuilds', gameId)
      const builds = await api.getBuilds(gameId, platform, type, store, search)

      context.state.buildsNextUrl[platform][gameId] = builds.next || 'FINISHED'
      context.commit('upsertBuilds', { gameId: gameId, builds: builds.results })

      return builds
    },
    async getBuildsNext({ state, commit }, { gameId, platform }) {
      if (state.buildsNextUrl[platform][gameId] === 'FINISHED') {
        return
      }

      const builds = await api.getBuildsNext(state.buildsNextUrl[platform][gameId])
      commit('upsertBuilds', { gameId: gameId, builds: builds.results })
      state.buildsNextUrl[platform][gameId] = builds.next || 'FINISHED'

      return builds
    },
    async postBuild({ commit }, params) {
      const build = await api.postBuild(params)
      const gameId = build.game
      commit('upsertBuilds', { gameId: gameId, builds: [build] })
      return build
    },
    async submitIpaToAppstore({ commit }, { build, uuid }) {
      const response = await api.submitIpaToAppstore(build.id, uuid)
      if (response.status === 'success') {
        commit('updateBuildFileSubmitted', {
          gameId: build.game,
          buildId: build.id,
          uuid: uuid,
        })
      }
      return response
    },
    async submitRebuildIOS({ commit }, { build, uuid }) {
      const response = await api.submitRebuildIOS(build.id, uuid)
      if (response.status === 'success') {
        commit('updateBuildFileRebuilt', {
          gameId: build.game,
          buildId: build.id,
        })
      }
    },
    async submitRebuildAndroid({ commit }, { build, uuid }) {
      const response = await api.submitRebuildAndroid(build.id, uuid)
      if (response.status === 'success') {
        commit('updateBuildFileRebuilt', {
          gameId: build.game,
          buildId: build.id,
        })
      }
    },
  },
  mutations: {
    clearBuilds(state, gameId) {
      Vue.set(state.builds, gameId, {})
    },
    upsertBuilds(state, { gameId, builds }) {
      if (state.builds[gameId] == null) Vue.set(state.builds, gameId, {})

      for (const build of builds) {
        const orig = state.builds[gameId][build.id] || {}
        Vue.set(state.builds[gameId], build.id, Object.assign(orig, build))
      }
    },
    updateBuildFileSubmitted(state, { gameId, buildId, uuid }) {
      Vue.set(
        state.builds[gameId][buildId].files.find(f => f.uuid === uuid),
        'submitted_to_appstore',
        true,
      )
    },
    updateBuildFileRebuilt(state, { gameId, buildId }) {
      Vue.set(state.builds[gameId][buildId], 'status', BuildStatus.BUILD)
    },
  },
}
