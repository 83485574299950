import * as api from '../api'
import Vue from 'vue'

function initialState() {
  return {
    pageSize: 10,
    pages: {},
  }
}

export default {
  state: initialState,
  getters: {
    resourcePageSize: state => state.pageSize,
  },
  actions: {
    async postResource({ state, dispatch }, { gameId, title, zip, type, description }) {
      const newResource = await api.postResource(gameId, title, zip, type, description)
      dispatch('getResourcesForGame', { gameId, environment: newResource.environment, page: 0 })
      return newResource
    },
    async getResource({ commit }, { resId }) {
      const updatedResource = await api.getResource(resId)
      commit('updateResource', updatedResource)
      return updatedResource
    },
    async getResourcesForGame(a, { gameId, environment, page }) {
      const { state, commit } = a
      const pageSize = state.pageSize
      const { count: total, results: resources } = await api.getResources({
        gameId,
        environment,
        limit: pageSize,
        offset: pageSize * page,
      })
      commit('updateResourcePage', { environment, page, total, resources })
    },
    async deleteResource({ state, dispatch }, { resource }) {
      await api.deleteResource(resource.id)
      dispatch('getResourcesForGame', {
        gameId: resource.game,
        environment: resource.environment,
        page: state.pages[resource.environment].current,
      })
    },
    async getResourceFiles({ state, dispatch }, { resource }) {
      const resourceFiles = await api.getResourceFiles(resource.id)
      return resourceFiles
    },
  },
  mutations: {
    updateResourcePage(state, { environment, page, total, resources }) {
      Vue.set(state.pages, environment, { total, current: page, resources })
    },
    updateResource(state, resource) {
      const environment = resource.environment
      if (!state.pages[environment]) return

      const idx = (state.pages[environment].resources || []).findIndex(r => r.id === resource.id)
      if (idx !== -1) {
        const orig = state.pages[environment].resources[idx]
        Object.assign(orig, resource)
      }
    },
    // XXX: 그런데 이럴거면 vuex를 왜쓰지..? 한번 고민해보자.
    // 반응형을 부여해주는 레이어와 비즈니스 로직을 분리한다 정도의 의미는 있지만 스토어의 본래 목적을 잘 살리고 있는 것 같지는 않다.
    resetResource(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
}
