import axios from 'axios'

export async function getUpdateRules(gameId) {
  const params = { game: gameId }
  return (await axios.get('/portal/updates/', { params })).data
}

export async function createUpdateRule(data) {
  return (await axios.post('/portal/updates/', data)).data
}

export async function modifyUpdateRule(data) {
  return (await axios.patch(`/portal/updates/${data.id}/`, data)).data
}

export async function deleteUpdateRule(updateRuleId) {
  return (await axios.delete(`/portal/updates/${updateRuleId}/`)).data
}

export async function getUpdateRulesHistory(gameId) {
  const params = { game: gameId }
  return (await axios.get('/portal/updates_history/', { params })).data
}
