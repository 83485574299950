import { AnalyticsReportParam } from './analytics_report_param'
import * as yaml from 'js-yaml'

export class AnalyticsReport {
  constructor({
    id,
    key,
    type,
    report,
    name,
    authorEmail,
    editorEmail,
    isPublic,
    hasFilter,
    createdAt,
    lastEditedAt,
    description,
    params = '',
    renderedParams = '',
    reportUrl,
    order = 10,
    modeMaxAge = 300,
  } = {}) {
    this.id = id
    this.key = key
    this.type = type
    this.report = report
    this.name = name
    this.description = description
    this.authorEmail = authorEmail
    this.editorEmail = editorEmail
    this.isPublic = isPublic
    this.hasFilter = hasFilter
    this.createdAt = createdAt
    this.lastEditedAt = lastEditedAt
    this.params = params
    this.renderedParams = renderedParams
    if (this.type === 'mode') {
      const parsedParams = yaml.load(this.renderedParams) || {}
      this.modeParams = Object.keys(parsedParams).reduce(
        (params, key) => ({
          ...params,
          [key]: AnalyticsReportParam.build(parsedParams[key]),
        }),
        {},
      )
    }
    this.reportUrl = reportUrl
    this.order = order
    this.modeMaxAge = modeMaxAge
  }
}
