import axios from 'axios'

export async function postServerImage(game, type, tag) {
  const data = {
    game: game,
    type: type,
    tag: tag,
  }

  return (await axios.post('/portal/servers/images/', data)).data
}

export async function getServerImagesForGame(gameCode, type, limit = 10, offset = 0, tag) {
  const params = { game: gameCode, type, limit, offset }
  if (tag) {
    params['tag_contains'] = tag
  }
  return (await axios.get('/portal/servers/images/', { params })).data
}

export async function getServerImageTypesForGame(gameId) {
  return (await axios.get(`/portal/servers/image_types/?game=${gameId}`)).data
}

export async function getServerMysqlDDLForGame(gameId) {
  return (await axios.get(`/portal/servers/mysql_ddl/?game=${gameId}`)).data
}

export async function postServerMysqlDDL(gameId, file) {
  const form = new FormData()
  form.append('game', gameId)
  form.append('name', file.name)
  form.append('file', file)
  return (await axios.post('/portal/servers/mysql_ddl/', form)).data
}

export async function getServerCouchbaseViewForGame(gameId) {
  return (await axios.get(`/portal/servers/couchbase_view/?game=${gameId}`)).data
}

export async function postServerCouchbaseView(gameId, file) {
  const form = new FormData()
  form.append('game', gameId)
  form.append('name', file.name)
  form.append('file', file)
  return (await axios.post('/portal/servers/couchbase_view/', form)).data
}

export async function postServerFarm(data) {
  return (await axios.post('/portal/servers/farms/', data)).data
}

export async function patchServerFarm(data) {
  return (await axios.patch('/portal/servers/farms/', data)).data
}

export async function updateServerFarm(serverFarmId, data) {
  return (await axios.patch(`/portal/servers/farms/${serverFarmId}/`, data)).data
}

export async function createWebTerminal(serverFarmId) {
  return (await axios.post(`/portal/servers/farms/${serverFarmId}/web-terminal/`)).data
}

export async function getServerFarm(serverFarmId) {
  return (await axios.get(`/portal/servers/farms/${serverFarmId}/`)).data
}

export async function getServerFarmsForGame(gameCode) {
  return (await axios.get(`/portal/servers/farms/?game=${gameCode}`)).data
}

export async function getServerFarmHistories(serverFarmId) {
  return (await axios.get(`/portal/servers/farms/${serverFarmId}/histories/`)).data
}

export async function deleteServerFarm(serverFarmId) {
  return (await axios.delete(`/portal/servers/farms/${serverFarmId}/`)).data
}

export async function getServerDatabaseSettingsForGame(gameId) {
  return (await axios.get(`/portal/servers/database_settings/?game=${gameId}`)).data
}

export async function restartServerFarm(serverFarmId, target) {
  return (await axios.post(`/portal/servers/farms/${serverFarmId}/restart/`)).data
}

export async function postServerDatabaseSetting({
  gameId,
  description,
  mysql,
  mysqlDDL,
  couchbase,
  couchbaseBuckets,
  redis,
  cockroachdb,
  cockroachdbDDL,
}) {
  const databases = {
    cockroachdb: { enabled: cockroachdb },
    couchbase: { enabled: couchbase },
    mysql: { enabled: mysql },
    redis: { enabled: redis },
  }
  if (cockroachdb) {
    databases.cockroachdb.ddl = cockroachdbDDL
  }
  if (couchbase) {
    databases.couchbase.buckets = couchbaseBuckets
  }
  if (mysql) {
    databases.mysql.ddl = mysqlDDL
  }
  const form = {
    description,
    databases,
  }

  return (await axios.post(`/portal/servers/database_settings/?game=${gameId}`, form)).data
}

export async function patchServerDatabaseSetting(databaseSettingId, request) {
  const form = {
    description: request.description,
  }
  return (await axios.patch(`/portal/servers/database_settings/${databaseSettingId}/`, form)).data
}

export async function getPodList(params) {
  return (await axios.get('/portal/pod/', { params })).data
}

export async function getPodLogs(params) {
  return (await axios.get('/portal/pod/logs/', { params })).data
}
