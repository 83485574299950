const Terms = () =>
  import(/* webpackChunkName: "component_terms" */ 'client/components/Games/Terms/Terms')
const WordFilter = () =>
  import(
    /* webpackChunkName: "component_wordfilter" */ 'client/components/Games/WordFilter/WordFilter'
  )
const Update = () =>
  import(/* webpackChunkName: "component_update" */ 'client/components/Games/Update/Main')
const Coupon = () =>
  import(/* webpackChunkName: "games_coupon" */ 'client/components/Games/Coupon/Main')
const CouponNew = () =>
  import(/* webpackChunkName: "games_coupon_new" */ 'client/components/Games/Coupon/New')
const CouponEdit = () =>
  import(/* webpackChunkName: "games_coupon_edit" */ 'client/components/Games/Coupon/Edit')
const CouponDetail = () =>
  import(/* webpackChunkName: "games_coupon_detail" */ 'client/components/Games/Coupon/Detail')
const Maintenance = () =>
  import(
    /* webpackChunkName: "component_maintenance" */ 'client/components/Games/Maintenance/MaintenanceMain'
  )
const DevPlayUser = () =>
  import(
    /* webpackChunkName: "component_devplay_user" */ 'client/components/Games/UserManagement/DevPlayUser'
  )
const DormantUser = () =>
  import(
    /* webpackChunkName: "component_dormant_user" */ 'client/components/Games/UserManagement/DormantUser'
  )
const Refunds = () =>
  import(/* webpackChunkName: "component_refunds" */ 'client/components/Games/Refunds/Main')
const Subscriptions = () =>
  import(
    /* webpackChunkName: "component_subscriptions" */ 'client/components/Games/Subscriptions/Main'
  )
const PromotionList = () =>
  import(
    /* webpackChunkName: "component_promotion" */ 'client/components/Games/Promotion/Notice/PromotionNoticeList'
  )
const DevplayPromotionManagement = () =>
  import(
    /* webpackChunkName: "component_promotion" */ 'client/components/Games/Promotion/DevplayPromotionManagement'
  )
const DevplayPromotionRegister = () =>
  import(
    /* webpackChunkName: "component_promotion" */ 'client/components/Games/Promotion/DevplayPromotionRegister'
  )
const PromotionNew = () =>
  import(
    /* webpackChunkName: "component_promotion" */ 'client/components/Games/Promotion/Notice/PromotionNoticeNew'
  )
const RemoteConfig = () =>
  import(
    /* webpackChunkName: "component_sync" */ 'client/components/Games/RemoteConfig/RemoteConfig'
  )

const PushEventManagement = () =>
  import(
    /* webpackChunkName: "component_push" */ 'client/components/Games/Push/PushEventManagement'
  )

const PushEventCreate = () =>
  import(/* webpackChunkName: "component_push" */ 'client/components/Games/Push/PushEventCreate')

const PushEventEdit = () =>
  import(/* webpackChunkName: "component_push" */ 'client/components/Games/Push/PushEventEdit')

const ItemManagement = () =>
  import(
    /* webpackChunkName: "component_itembroker" */ 'client/components/Games/ItemBroker/ItemManagement'
  )

const ItemManualDelivery = () =>
  import(
    /* webpackChunkName: "component_itembroker" */ 'client/components/Games/ItemBroker/ItemManualDelivery'
  )

const ItemManualDeliveryRegister = () =>
  import(
    /* webpackChunkName: "component_itembroker" */ 'client/components/Games/ItemBroker/ItemManualDeliveryRegister'
  )

const ItemDeliveryStatus = () =>
  import(
    /* webpackChunkName: "component_itembroker" */ 'client/components/Games/ItemBroker/ItemDeliveryStatus'
  )

const ItemDeliveryWebhookManagement = () =>
  import(
    /* webpackChunkName: "component_itembroker" */ 'client/components/Games/ItemBroker/ItemDeliveryWebhookManagement'
  )

import { DISTRIBUTION, OPERATION } from 'client/components/Layout/MenuGroups/consts'

const routes = [
  {
    path: '/games/:gameCode/terms-common',
    component: Terms,
    name: 'terms-common',
    props: route => ({
      currentRegion: route.query.region,
      isCommon: true,
      requiredPermissions: ['portal.modify_common_terms'],
    }),
    meta: {
      title: 'message.HomeLabelCommonterms',
      group: OPERATION,
      guideDocumentCode: 'terms',
    },
  },
  {
    path: '/games/:gameCode/terms-game',
    component: Terms,
    name: 'terms-game',
    props: route => ({
      currentRegion: route.query.region,
      isCommon: false,
      requiredPermissions: ['portal.modify_game_terms'],
    }),
    meta: {
      title: 'message.HomeLabelGameterms',
      group: OPERATION,
      guideDocumentCode: 'terms',
    },
  },
  {
    path: '/games/:gameCode/wordfilter-common',
    component: WordFilter,
    name: 'wordfilter-common',
    props: route => ({
      isCommon: true,
      requiredPermissions: ['portal.update_wordfilter'],
    }),
    meta: {
      title: 'message.HomeLabelCommonwordfilter',
      group: OPERATION,
      guideDocumentCode: 'wordfilter',
    },
  },
  {
    path: '/games/:gameCode/wordfilter-game',
    component: WordFilter,
    name: 'wordfilter-game',
    props: route => ({
      isCommon: false,
      requiredPermissions: ['portal.update_wordfilter'],
    }),
    meta: {
      title: 'message.HomeLabelGamewordfilter',
      group: OPERATION,
      guideDocumentCode: 'wordfilter',
    },
  },
  {
    path: '/games/:gameCode/maintenance',
    component: Maintenance,
    name: 'maintenance',
    meta: {
      title: 'message.HomeLabelMaintenance',
      group: OPERATION,
      guideDocumentCode: 'maintenance',
    },
  },
  {
    path: '/games/:gameCode/coupon',
    component: Coupon,
    name: 'coupon-management',
    meta: {
      title: 'message.HomeLabelCouponmanage',
      group: OPERATION,
      guideDocumentCode: 'devplay-console-coupon',
    },
    props: {
      requiredPermissions: ['portal.modify_coupons'],
    },
  },
  {
    path: '/games/:gameCode/coupon/new',
    component: CouponNew,
    name: 'coupon-new',
    meta: {
      title: 'message.HomeLabelCouponnew',
      group: OPERATION,
      guideDocumentCode: 'devplay-console-coupon',
    },
    props: {
      requiredPermissions: ['portal.modify_coupons'],
    },
  },
  {
    path: '/games/:gameCode/coupon/edit/:couponEventId',
    component: CouponEdit,
    name: 'coupon-edit',
    meta: {
      title: 'message.HomeLabelCouponedit',
      group: OPERATION,
      guideDocumentCode: 'devplay-console-coupon',
    },
    props: {
      requiredPermissions: ['portal.modify_coupons'],
    },
  },
  {
    path: '/games/:gameCode/coupon/:couponEventId',
    component: CouponDetail,
    name: 'coupon-detail',
    meta: {
      title: 'message.HomeLabelCoupondetail',
      group: OPERATION,
      guideDocumentCode: 'devplay-console-coupon',
    },
    props: {
      requiredPermissions: ['portal.modify_coupons'],
    },
  },
  {
    path: '/games/:gameCode/update',
    component: Update,
    name: 'update',
    meta: {
      title: 'message.HomeLabelUpdate',
      group: OPERATION,
      guideDocumentCode: 'portal-version-update',
    },
  },
  {
    path: '/games/:gameCode/promotions',
    component: PromotionList,
    name: 'promotions-list',
    meta: {
      title: 'message.HomeLabelPromotionnotice',
      group: OPERATION,
      guideDocumentCode: 'promotion_notice',
    },
  },
  {
    path: '/games/:gameCode/devplay-promotion/crossgame',
    component: DevplayPromotionManagement,
    name: 'crossgame-promotion',
    meta: {
      title: 'message.HomeLabelPromotioncross',
      group: OPERATION,
      guideDocumentCode: 'devplay-promotion',
    },
    props: {
      requiredPermissions: ['portal.modify_promotion'],
      promotionType: 'crossgame',
    },
  },
  {
    path: '/games/:gameCode/devplay-promotion-new/crossgame/:promotionId?',
    component: DevplayPromotionRegister,
    name: 'devplay-promotion-crossgame-new',
    meta: {
      title: 'message.HomeLabelPromotioncrossreg',
      group: OPERATION,
      guideDocumentCode: 'devplay-promotion',
    },
    props: {
      requiredPermissions: ['portal.modify_promotion'],
    },
  },
  {
    path: '/games/:gameCode/devplay-promotion-update/crossgame/:promotionId',
    component: DevplayPromotionRegister,
    name: 'devplay-promotion-crossgame-update',
    meta: {
      title: 'message.HomeLabelPromotioncrossmod',
      group: OPERATION,
      guideDocumentCode: 'devplay-promotion',
    },
    props: {
      requiredPermissions: ['portal.modify_promotion'],
    },
  },
  {
    path: '/games/:gameCode/promotions/new',
    component: PromotionNew,
    name: 'promotions-new',
    meta: {
      title: 'message.HomeLabelPromotionnoticereg',
      group: OPERATION,
      guideDocumentCode: 'promotion_notice',
    },
  },
  {
    path: '/games/:gameCode/promotions/:promotionId',
    component: PromotionNew,
    name: 'promotions-edit',
    meta: {
      title: 'message.HomeLabelPromotionnoticemod',
      group: OPERATION,
      guideDocumentCode: 'promotion_notice',
    },
  },
  {
    path: '/games/:gameCode/devplay-user',
    component: DevPlayUser,
    name: 'devplay-user',
    meta: {
      title: 'message.HomeLabelUsermanage',
      group: OPERATION,
      guideDocumentCode: 'auth-user-mgmt',
    },
  },
  {
    path: '/games/:gameCode/dormant-user',
    component: DormantUser,
    name: 'dormant-user',
    meta: {
      title: 'message.HomeLabelDormantusermanage',
      group: OPERATION,
    },
  },
  {
    path: '/games/:gameCode/refunds',
    component: Refunds,
    name: 'refunds',
    meta: {
      title: 'message.HomeLabelRefundinfo',
      group: OPERATION,
    },
    props: {
      requiredPermissions: ['portal.get_refunds'],
    },
  },
  {
    path: '/games/:gameCode/subscriptions',
    component: Subscriptions,
    name: 'subscriptions',
    meta: {
      title: 'message.HomeLabelSubscriptioninfo',
      group: OPERATION,
    },
    props: {
      requiredPermissions: ['portal.get_refunds'],
    },
  },
  {
    path: '/games/:gameCode/remoteconfig',
    component: RemoteConfig,
    name: 'remote-config',
    meta: {
      title: 'message.HomeLabelRemoteconfig',
      group: OPERATION,
      guideDocumentCode: 'RemoteConfig',
    },
    props: () => ({
      requiredPermissions: ['portal.modify_remoteconfig'],
    }),
  },
  {
    path: '/games/:gameCode/push',
    component: PushEventManagement,
    name: 'push-event-management',
    meta: {
      title: 'message.HomeLabelPush',
      group: OPERATION,
      guideDocumentCode: 'devplay-console-push',
    },
  },
  {
    path: '/games/:gameCode/push/create',
    component: PushEventCreate,
    name: 'push-event-create',
    meta: {
      title: 'message.HomeLabelPushcreate',
      group: OPERATION,
      guideDocumentCode: 'devplay-console-push',
    },
  },
  {
    path: '/games/:gameCode/push/create/:eventId',
    component: PushEventCreate,
    name: 'push-event-create',
    meta: {
      title: 'message.HomeLabelPushcreate',
      group: OPERATION,
      guideDocumentCode: 'devplay-console-push',
    },
  },
  {
    path: '/games/:gameCode/push/edit/:eventId',
    component: PushEventEdit,
    name: 'push-event-edit',
    meta: {
      title: 'message.HomeLabelPushmod',
      group: OPERATION,
      guideDocumentCode: 'devplay-console-push',
    },
  },
  {
    path: '/games/:gameCode/itembroker/item-management',
    component: ItemManagement,
    name: 'item-management',
    meta: {
      title: 'message.HomeLabelItemmanage',
      group: OPERATION,
      guideDocumentCode: 'item_management',
    },
    props: () => ({
      requiredPermissions: ['portal.modify_itembroker'],
    }),
  },
  {
    path: '/games/:gameCode/itembroker/item-manual-delivery',
    component: ItemManualDelivery,
    name: 'item-manual-delivery',
    meta: {
      title: 'message.HomeLabelItemmanualdelivery',
      group: OPERATION,
      guideDocumentCode: 'item_manual_delivery',
    },
    props: () => ({
      requiredPermissions: ['portal.modify_itembroker'],
    }),
  },
  {
    path: '/games/:gameCode/itembroker/item-manual-delivery-register',
    component: ItemManualDeliveryRegister,
    name: 'item-manual-delivery-register',
    meta: {
      title: 'message.HomeLabelItemmanualdeliveryreg',
      group: OPERATION,
      guideDocumentCode: 'item_manual_delivery',
    },
    props: () => ({
      requiredPermissions: ['portal.modify_itembroker'],
    }),
  },
  {
    path: '/games/:gameCode/itembroker/item-manual-delivery-update/:manualDeliveryId',
    component: ItemManualDeliveryRegister,
    name: 'item-manual-delivery-update',
    meta: {
      title: 'message.HomeLabelItemmanualdeliverymod',
      group: OPERATION,
      guideDocumentCode: 'item_manual_delivery',
    },
    props: () => ({
      requiredPermissions: ['portal.modify_itembroker'],
    }),
  },
  {
    path: '/games/:gameCode/itembroker/item-delivery-status',
    component: ItemDeliveryStatus,
    name: 'item-delivery-status',
    meta: {
      title: 'message.HomeLabelItemdeliverystatus',
      group: OPERATION,
    },
    props: () => ({
      requiredPermissions: ['portal.modify_itembroker'],
    }),
  },
  {
    path: '/games/:gameCode/itembroker/item-delivery-webhook-management',
    component: ItemDeliveryWebhookManagement,
    name: 'item-delivery-webhook-management',
    meta: {
      title: 'message.HomeLabelItemdeliverywebhookmanagement',
      group: OPERATION,
    },
    props: () => ({
      requiredPermissions: ['portal.modify_itembroker'],
    }),
  },
]

export default routes
