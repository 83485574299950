// source: devplay/coupon/internals/event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var devplay_coupon_internals_coupon_resources_pb = require('../../../devplay/coupon/internals/coupon_resources_pb.js');
goog.object.extend(proto, devplay_coupon_internals_coupon_resources_pb);
goog.exportSymbol('proto.devplay.coupon.internals.CreateEventRequest', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.GetEventRequest', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.GetWordTypeEventUsageRequest', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.GetWordTypeEventUsageResponse', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.SearchEventsRequest', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.SearchEventsResponse', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.UpdateEventRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.SearchEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.SearchEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.SearchEventsRequest.displayName = 'proto.devplay.coupon.internals.SearchEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.SearchEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devplay.coupon.internals.SearchEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.devplay.coupon.internals.SearchEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.SearchEventsResponse.displayName = 'proto.devplay.coupon.internals.SearchEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.GetEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.GetEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.GetEventRequest.displayName = 'proto.devplay.coupon.internals.GetEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.displayName = 'proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.displayName = 'proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.GetWordTypeEventUsageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.displayName = 'proto.devplay.coupon.internals.GetWordTypeEventUsageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.GetWordTypeEventUsageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.displayName = 'proto.devplay.coupon.internals.GetWordTypeEventUsageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.CreateEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.CreateEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.CreateEventRequest.displayName = 'proto.devplay.coupon.internals.CreateEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.UpdateEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devplay.coupon.internals.UpdateEventRequest.repeatedFields_, null);
};
goog.inherits(proto.devplay.coupon.internals.UpdateEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.UpdateEventRequest.displayName = 'proto.devplay.coupon.internals.UpdateEventRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.SearchEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.SearchEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.SearchEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    couponName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hideEnded: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    pageToken: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.SearchEventsRequest}
 */
proto.devplay.coupon.internals.SearchEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.SearchEventsRequest;
  return proto.devplay.coupon.internals.SearchEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.SearchEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.SearchEventsRequest}
 */
proto.devplay.coupon.internals.SearchEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideEnded(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.SearchEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.SearchEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.SearchEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCouponName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHideEnded();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string game_code = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.SearchEventsRequest} returns this
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string coupon_name = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.getCouponName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.SearchEventsRequest} returns this
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.setCouponName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool hide_ended = 3;
 * @return {boolean}
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.getHideEnded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.devplay.coupon.internals.SearchEventsRequest} returns this
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.setHideEnded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 page_token = 4;
 * @return {number}
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.SearchEventsRequest} returns this
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.SearchEventsRequest} returns this
 */
proto.devplay.coupon.internals.SearchEventsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devplay.coupon.internals.SearchEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.SearchEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.SearchEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.SearchEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.SearchEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    devplay_coupon_internals_coupon_resources_pb.Event.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.SearchEventsResponse}
 */
proto.devplay.coupon.internals.SearchEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.SearchEventsResponse;
  return proto.devplay.coupon.internals.SearchEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.SearchEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.SearchEventsResponse}
 */
proto.devplay.coupon.internals.SearchEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new devplay_coupon_internals_coupon_resources_pb.Event;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.SearchEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.SearchEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.SearchEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.SearchEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      devplay_coupon_internals_coupon_resources_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.devplay.coupon.internals.Event>}
 */
proto.devplay.coupon.internals.SearchEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.devplay.coupon.internals.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, devplay_coupon_internals_coupon_resources_pb.Event, 1));
};


/**
 * @param {!Array<!proto.devplay.coupon.internals.Event>} value
 * @return {!proto.devplay.coupon.internals.SearchEventsResponse} returns this
*/
proto.devplay.coupon.internals.SearchEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.devplay.coupon.internals.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.Event}
 */
proto.devplay.coupon.internals.SearchEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.devplay.coupon.internals.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.SearchEventsResponse} returns this
 */
proto.devplay.coupon.internals.SearchEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional int32 next_page_token = 2;
 * @return {number}
 */
proto.devplay.coupon.internals.SearchEventsResponse.prototype.getNextPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.SearchEventsResponse} returns this
 */
proto.devplay.coupon.internals.SearchEventsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.GetEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.GetEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.GetEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.GetEventRequest}
 */
proto.devplay.coupon.internals.GetEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.GetEventRequest;
  return proto.devplay.coupon.internals.GetEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.GetEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.GetEventRequest}
 */
proto.devplay.coupon.internals.GetEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.GetEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.GetEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.GetEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.GetEventRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.GetEventRequest} returns this
 */
proto.devplay.coupon.internals.GetEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest;
  return proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest} returns this
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usedCouponCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usedPlayerCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse;
  return proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsedCouponCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsedPlayerCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsedCouponCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUsedPlayerCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 used_coupon_count = 1;
 * @return {number}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.prototype.getUsedCouponCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse} returns this
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.prototype.setUsedCouponCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 used_player_count = 2;
 * @return {number}
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.prototype.getUsedPlayerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse} returns this
 */
proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.prototype.setUsedPlayerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.GetWordTypeEventUsageRequest;
  return proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest} returns this
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usedCouponCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.GetWordTypeEventUsageResponse}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.GetWordTypeEventUsageResponse;
  return proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.GetWordTypeEventUsageResponse}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsedCouponCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsedCouponCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 used_coupon_count = 1;
 * @return {number}
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.prototype.getUsedCouponCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.GetWordTypeEventUsageResponse} returns this
 */
proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.prototype.setUsedCouponCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.CreateEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.CreateEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.CreateEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.CreateEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && devplay_coupon_internals_coupon_resources_pb.Event.toObject(includeInstance, f),
    couponCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.CreateEventRequest}
 */
proto.devplay.coupon.internals.CreateEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.CreateEventRequest;
  return proto.devplay.coupon.internals.CreateEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.CreateEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.CreateEventRequest}
 */
proto.devplay.coupon.internals.CreateEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new devplay_coupon_internals_coupon_resources_pb.Event;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.CreateEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.CreateEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.CreateEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.CreateEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      devplay_coupon_internals_coupon_resources_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Event event = 1;
 * @return {?proto.devplay.coupon.internals.Event}
 */
proto.devplay.coupon.internals.CreateEventRequest.prototype.getEvent = function() {
  return /** @type{?proto.devplay.coupon.internals.Event} */ (
    jspb.Message.getWrapperField(this, devplay_coupon_internals_coupon_resources_pb.Event, 1));
};


/**
 * @param {?proto.devplay.coupon.internals.Event|undefined} value
 * @return {!proto.devplay.coupon.internals.CreateEventRequest} returns this
*/
proto.devplay.coupon.internals.CreateEventRequest.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.CreateEventRequest} returns this
 */
proto.devplay.coupon.internals.CreateEventRequest.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.CreateEventRequest.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string coupon_code = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.CreateEventRequest.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.CreateEventRequest} returns this
 */
proto.devplay.coupon.internals.CreateEventRequest.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devplay.coupon.internals.UpdateEventRequest.repeatedFields_ = [5,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.UpdateEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.UpdateEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.UpdateEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hasTargetCountries: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    targetCountriesList: jspb.Message.toObjectList(msg.getTargetCountriesList(),
    devplay_coupon_internals_coupon_resources_pb.Country.toObject, includeInstance),
    inboxId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    titleMessage: (f = msg.getTitleMessage()) && devplay_coupon_internals_coupon_resources_pb.TranslatedMessage.toObject(includeInstance, f),
    bodyMessage: (f = msg.getBodyMessage()) && devplay_coupon_internals_coupon_resources_pb.TranslatedMessage.toObject(includeInstance, f),
    rewardsList: jspb.Message.toObjectList(msg.getRewardsList(),
    devplay_coupon_internals_coupon_resources_pb.Reward.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest}
 */
proto.devplay.coupon.internals.UpdateEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.UpdateEventRequest;
  return proto.devplay.coupon.internals.UpdateEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.UpdateEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest}
 */
proto.devplay.coupon.internals.UpdateEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasTargetCountries(value);
      break;
    case 5:
      var value = new devplay_coupon_internals_coupon_resources_pb.Country;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.Country.deserializeBinaryFromReader);
      msg.addTargetCountries(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInboxId(value);
      break;
    case 7:
      var value = new devplay_coupon_internals_coupon_resources_pb.TranslatedMessage;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.TranslatedMessage.deserializeBinaryFromReader);
      msg.setTitleMessage(value);
      break;
    case 8:
      var value = new devplay_coupon_internals_coupon_resources_pb.TranslatedMessage;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.TranslatedMessage.deserializeBinaryFromReader);
      msg.setBodyMessage(value);
      break;
    case 9:
      var value = new devplay_coupon_internals_coupon_resources_pb.Reward;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.Reward.deserializeBinaryFromReader);
      msg.addRewards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.UpdateEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.UpdateEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.UpdateEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHasTargetCountries();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTargetCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      devplay_coupon_internals_coupon_resources_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getInboxId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTitleMessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      devplay_coupon_internals_coupon_resources_pb.TranslatedMessage.serializeBinaryToWriter
    );
  }
  f = message.getBodyMessage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      devplay_coupon_internals_coupon_resources_pb.TranslatedMessage.serializeBinaryToWriter
    );
  }
  f = message.getRewardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      devplay_coupon_internals_coupon_resources_pb.Reward.serializeBinaryToWriter
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
*/
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
*/
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool has_target_countries = 4;
 * @return {boolean}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getHasTargetCountries = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setHasTargetCountries = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated Country target_countries = 5;
 * @return {!Array<!proto.devplay.coupon.internals.Country>}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getTargetCountriesList = function() {
  return /** @type{!Array<!proto.devplay.coupon.internals.Country>} */ (
    jspb.Message.getRepeatedWrapperField(this, devplay_coupon_internals_coupon_resources_pb.Country, 5));
};


/**
 * @param {!Array<!proto.devplay.coupon.internals.Country>} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
*/
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setTargetCountriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.devplay.coupon.internals.Country=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.Country}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.addTargetCountries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.devplay.coupon.internals.Country, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.clearTargetCountriesList = function() {
  return this.setTargetCountriesList([]);
};


/**
 * optional string inbox_id = 6;
 * @return {string}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getInboxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setInboxId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TranslatedMessage title_message = 7;
 * @return {?proto.devplay.coupon.internals.TranslatedMessage}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getTitleMessage = function() {
  return /** @type{?proto.devplay.coupon.internals.TranslatedMessage} */ (
    jspb.Message.getWrapperField(this, devplay_coupon_internals_coupon_resources_pb.TranslatedMessage, 7));
};


/**
 * @param {?proto.devplay.coupon.internals.TranslatedMessage|undefined} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
*/
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setTitleMessage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.clearTitleMessage = function() {
  return this.setTitleMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.hasTitleMessage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TranslatedMessage body_message = 8;
 * @return {?proto.devplay.coupon.internals.TranslatedMessage}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getBodyMessage = function() {
  return /** @type{?proto.devplay.coupon.internals.TranslatedMessage} */ (
    jspb.Message.getWrapperField(this, devplay_coupon_internals_coupon_resources_pb.TranslatedMessage, 8));
};


/**
 * @param {?proto.devplay.coupon.internals.TranslatedMessage|undefined} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
*/
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setBodyMessage = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.clearBodyMessage = function() {
  return this.setBodyMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.hasBodyMessage = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Reward rewards = 9;
 * @return {!Array<!proto.devplay.coupon.internals.Reward>}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.getRewardsList = function() {
  return /** @type{!Array<!proto.devplay.coupon.internals.Reward>} */ (
    jspb.Message.getRepeatedWrapperField(this, devplay_coupon_internals_coupon_resources_pb.Reward, 9));
};


/**
 * @param {!Array<!proto.devplay.coupon.internals.Reward>} value
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
*/
proto.devplay.coupon.internals.UpdateEventRequest.prototype.setRewardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.devplay.coupon.internals.Reward=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.Reward}
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.addRewards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.devplay.coupon.internals.Reward, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.UpdateEventRequest} returns this
 */
proto.devplay.coupon.internals.UpdateEventRequest.prototype.clearRewardsList = function() {
  return this.setRewardsList([]);
};


goog.object.extend(exports, proto.devplay.coupon.internals);
