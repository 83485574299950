import * as api from '../api'

export default {
  actions: {
    async searchDocument({ state, commit }, { search, fromAllVersions, page }) {
      const res = await api.searchDocument(search, fromAllVersions, page)
      if (page === 1 || page === undefined) {
        commit('clearSearchResults')
      }
      commit('appendSearchResults', { results: res.results, hasNext: !!res.next })
    },
  },
  state: {
    showMobileDocumentsList: false,

    toc: '',
    tocPluginInitialized: false,

    searchResults: [],
    hasNextSearchResults: false,
  },
  mutations: {
    updateToc(state, toc) {
      state.toc = toc
    },
    markTocPluginInitialized(state) {
      state.tocPluginInitialized = true
    },
    clearSearchResults(state) {
      state.searchResults = []
      state.hasNextSearchResults = false
    },
    appendSearchResults(state, { results, hasNext }) {
      state.searchResults = state.searchResults.concat(results)
      state.hasNextSearchResults = hasNext
    },
    toggleShowDocumentsList(state) {
      state.showMobileDocumentsList = !state.showMobileDocumentsList
    },
  },
}
