import Vue from 'vue'
import * as api from '../api'

export default {
  actions: {
    async getUpdateRules({ commit }, { game }) {
      // note: mutation을 호출할 때 파라미터가 2개 이상인 경우 { } 로 Wrapping 해야 함.
      // note: 또한 mutation에 여러 인자가 들어가는 경우 변수명이 일치해야 함.
      const updateRules = await api.getUpdateRules(game.id)
      const envs = await api.getEnvironments()
      commit('changeUpdateRules', { game, updateRules, envs })
    },
    async createUpdateRule({ commit }, { game, data, form }) {
      const updateRules = []
      const parameters = {
        game: game.id,
        env: data.env,
        type: data.type,
        platform: data.platform,
        version: form.version,
        available: data.type === 'forced' ? form.available : true,
      }
      if (form.extraVersions && form.extraVersions.length > 0) {
        Vue.set(parameters, 'extra_versions', form.extraVersions.join(','))
      }
      updateRules.push(await api.createUpdateRule(parameters))
      const envs = await api.getEnvironments()
      commit('changeUpdateRules', { game, updateRules, envs })

      const updateRulesHistory = await api.getUpdateRulesHistory(game.id)
      commit('changeUpdateRulesHistory', { game, updateRulesHistory })
    },
    async modifyUpdateRule({ commit }, { game, data, form }) {
      const updateRules = []
      const parameters = {
        id: data.id,
        game: game.id,
        env: data.env,
        type: data.type,
        platform: data.platform,
        version: form.version,
        available: data.type === 'forced' ? form.available : true,
      }
      if (form.extraVersions && form.extraVersions.length > 0) {
        Vue.set(parameters, 'extra_versions', form.extraVersions.join(','))
      } else {
        Vue.set(parameters, 'extra_versions', '')
      }
      updateRules.push(await api.modifyUpdateRule(parameters))
      const envs = await api.getEnvironments()
      commit('changeUpdateRules', { game, updateRules, envs })

      const updateRulesHistory = await api.getUpdateRulesHistory(game.id)
      commit('changeUpdateRulesHistory', { game, updateRulesHistory })
    },
    async deleteUpdateRule({ commit }, { game, data }) {
      const updateRules = []
      updateRules.push(await api.deleteUpdateRule(data))
      commit('deleteUpdateRule', { game, updateRules })

      const updateRulesHistory = await api.getUpdateRulesHistory(game.id)
      commit('changeUpdateRulesHistory', { game, updateRulesHistory })
    },
    async getUpdateRulesHistory({ commit }, { game }) {
      const updateRulesHistory = await api.getUpdateRulesHistory(game.id)
      commit('changeUpdateRulesHistory', { game, updateRulesHistory })
    },
  },
  state: {
    updateRules: {},
    updateRulesHistory: {},
    envs: {},
  },
  mutations: {
    changeUpdateRules(state, { game, updateRules, envs }) {
      state.envs = envs.sort(({ order: l }, { order: r }) => l - r)

      // 키가 없을 경우 새로 생성함
      if (state.updateRules[game.id] === undefined) {
        Vue.set(state.updateRules, game.id, {})
      }

      const newUpdateRulesObject = {}
      // 객체를 열거하려면 in 대신 of 를 사용해야 함
      for (const updateRule of updateRules) {
        // 두 값이 다른 경우 새 값으로 업데이트해줌
        if (!Object.is(state.updateRules[game.id][updateRule.id], updateRule)) {
          newUpdateRulesObject[updateRule.id] = updateRule
        }
      }

      state.updateRules[game.id] = Object.assign(
        {},
        state.updateRules[game.id],
        newUpdateRulesObject,
      )

      const envList = state.envs.map(x => x.name)
      const platformList = ['ios', 'android']
      const typeList = ['soft', 'forced']
      const itemList = []

      // 프로덕트 곱 생성
      for (const env of envList) {
        for (const platform of platformList) {
          for (const type of typeList) {
            itemList.push({ env: env, platform: platform, type: type, updateRule: null })
          }
        }
      }

      // 서버로부터 반환받은 항목들만 배열에 추가
      let count = 0
      const missingUpdateRulesObject = {}
      for (const eachItem of itemList) {
        for (const eachUpdateRuleKey in state.updateRules[game.id]) {
          const eachUpdateRule = state.updateRules[game.id][eachUpdateRuleKey]
          if (
            eachUpdateRule.env === eachItem.env &&
            eachUpdateRule.platform === eachItem.platform &&
            eachUpdateRule.type === eachItem.type &&
            eachUpdateRule.hasOwnProperty('id')
          ) {
            Vue.set(eachUpdateRule, 'new_item', false)
            eachItem.updateRule = eachUpdateRule
          }
        }
      }

      // 반환되지 않은 항목들은 따로 데이터를 생성함
      for (const eachItem of itemList) {
        if (eachItem.updateRule) {
          continue
        }
        missingUpdateRulesObject['missing_' + (count++).toString()] = eachItem.updateRule = {
          env: eachItem.env,
          platform: eachItem.platform,
          type: eachItem.type,
          version: '',
          extra_versions: '',
          available: true,
          new_item: true,
        }
      }

      // 비어있는 object 들을 다시 추가
      state.updateRules[game.id] = Object.assign(
        {},
        state.updateRules[game.id],
        missingUpdateRulesObject,
      )
    },
    deleteUpdateRule(state, { game, updateRule }) {
      Vue.delete(state.updateRules[game.id], updateRule.id)
    },
    changeUpdateRulesHistory(state, { game, updateRulesHistory }) {
      // 키가 없을 경우 새로 생성함
      if (state.updateRulesHistory[game.id] === undefined) {
        Vue.set(state.updateRulesHistory, game.id, {})
      }

      const newUpdateRulesHistoryObject = {}
      for (const updateRuleHistory of updateRulesHistory) {
        // 두 값이 다른 경우 새 값으로 업데이트해줌
        if (
          !Object.is(state.updateRulesHistory[game.id][updateRuleHistory.id], updateRuleHistory)
        ) {
          newUpdateRulesHistoryObject[updateRuleHistory.id] = updateRuleHistory
        }
      }
      state.updateRulesHistory[game.id] = Object.assign(
        {},
        state.updateRulesHistory[game.id],
        newUpdateRulesHistoryObject,
      )
    },
  },
}
