import axios from 'axios'
import { getAccessToken } from 'client/misc/token'
import { getEnvironments } from 'client/api/environments'
import Message from 'element-ui/lib/message'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import decamelizeKeysDeep from 'decamelize-keys-deep'

let endpoints = {}

export async function getReceptionEndpoint(env) {
  if (endpoints.hasOwnProperty(env)) {
    return endpoints[env]
  }
  const environments = await getEnvironments()
  for (const environment of environments) {
    endpoints[environment.name] = environment.console_endpoint
  }
  if (endpoints.hasOwnProperty(env)) {
    return endpoints[env]
  }

  const errMessage = `${env} 의 Endpoint 주소를 찾을 수 없습니다`
  Message({
    type: 'error',
    message: errMessage,
    duration: 10000, // 10sec
  })
  throw new Error(errMessage)
}

export async function getClient(env) {
  const endpoint = await getReceptionEndpoint(env)
  const token = getAccessToken()
  const client = axios.create({
    baseURL: endpoint,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  })

  client.interceptors.response.use(response => response, handleError)

  return client
}

/**
 * HTTP 상태코드에 따른 에러 메시지 정보를 나타낸다.
 *
 * 키: HTTP 상태코드
 * 값: HTTP 상태코드에 해당하는 에러 메시지 옵션 객체
 */
const HTTP_STATUS_CODE_ERROR_MESSAGE = {
  [400]: { type: 'error', message: '잘못된 요청입니다.' },
  [401]: { type: 'error', message: '로그인이 필요합니다.' },
  [403]: { type: 'error', message: '권한이 없습니다.' },
  [404]: { type: 'warning', message: '찾을 수 없습니다.' },
}

/**
 * 커스텀 코드에 따른 에러 메시지 정보를 나타낸다.
 *
 * 키: 커스텀 코드
 * 값: 커스텀 코드에 해당하는 에러 메시지 옵션 객체
 *      - type <string>: Alert type
 *      - message <string>: Alert message
 *      - directManage <boolean>: axios interceptor에서 처리하지 않고 싶을때 true로 설정, 호출한 컴포넌트에서 직접 에러처리한다.
 */
const CUSTOM_CODE_ERROR_MESSAGE = {
  [40006]: { type: 'warning', message: '사용자를 찾을 수 없습니다.' },
  [40151]: { type: 'error', message: 'API 인증 토큰이 유효하지 않습니다.' },
  [40213]: {
    type: 'warning',
    message: '이메일 바운스 대상이 아닙니다. 이메일 수신 가능한 상태입니다',
  },
  [40214]: {
    type: 'warning',
    message:
      '기존 플레이어를 삭제 할 수 없는 상태입니다. (기존 Player 정보에 결제 및 쿠폰 사용 이력이 존재)',
  },
  [40302]: { type: 'error', message: '마지막 SNS 계정은 연동해제할 수 없습니다.' },
  [40351]: { type: 'error', message: '권한이 없습니다.' },
  [46201]: { type: 'warning', message: 'AB 테스트의 실험 처치 생성조건이 올바르지 않습니다.' },
  [46202]: { type: 'warning', message: '해당 실험 설정이 존재하지 않습니다.' },
  [46203]: { type: 'error', message: '실험 생성 도중 에러가 발생했습니다.' },
  [46204]: { type: 'warning', message: '실험 설정이 유효하지 않습니다.' },
  [46205]: { type: 'warning', message: '이미 수정된 실험입니다. 새로고침 후 다시 시도해주세요.' },
  [46206]: { type: 'error', message: '요청한 그룹 목록이 원래의 설정과 맞지 않습니다.' },
  [46207]: { type: 'error', message: '그룹 설정 도중 에러가 발생했습니다.' },
  [46208]: { type: 'warning', message: '요청한 실험이 존재하지 않습니다.' },
  [46209]: { type: 'error', message: '실험 변경 기록을 생성하지 못 하여 실패했습니다.' },
  [47202]: { type: 'warning', message: '키가 동일한 Remote Config 가 존재합니다.' },
  [47203]: { type: 'warning', message: 'Remote Config의 Value가 유효하지 않습니다.' },
  [47204]: { type: 'warning', message: 'Remote Config의 Type은 변경될 수 없습니다.' },
  [47205]: { type: 'warning', message: 'Remote Config 를 찾을 수 없습니다.' },
  [47209]: { type: 'warning', message: 'Remote Config 를 삭제할 수 없습니다.', directManage: true },
  [50101]: { type: 'error', message: '휴면유저 시스템에서 에러가 발생하였습니다.' },
}

/**
 * HTTP 응답에 따라 에러 메시지를 띄우는 함수이다.
 * HTTP 상태코드와 커스텀 코드의 존재유무에 따라 띄우는 에러 메시지가 달라진다.
 *
 * HTTP 응답 본문내에 커스텀 코드가 존재하는 경우:
 *    핸들링하는 커스텀 코드인 경우: 커스텀 코드에 해당하는 에러 메시지를 띄운다.
 *    핸들링하지않는 커스텀 코드인 경우:
 *        핸들링하는 HTTP 상태코드인 경우: 커스텀 코드명과 HTTP 상태코드에 해당하는 에러메시지를 띄운다.
 *        핸들링하지않는 HTTP 상태코드인 경우: 커스텀 코드명과 기본 에러 메시지를 띄운다.
 *
 * HTTP 응답 본문내에 커스텀 코드가 존재하지 않는 경우:
 *    핸들링하는 HTTP 상태코드인 경우: HTTP 상태코드명과 HTTP 상태코드에 해당하는 에러 메시지를 띄운다.
 *    핸들링하지않는 HTTP 상태코드인 경우: HTTP 상태코드명과 기본 에러 메시지를 띄운다.
 *
 * @param error Axios 에러 객체
 * @returns {Promise<never>}
 */
function handleError(error) {
  // 리셉션 서버와 통신 자체가 불가능한 경우
  if (!error.response) {
    Message({
      message: '서버와 통신할 수 없습니다. 관리자에게 문의하세요.',
      type: 'error',
      duration: 2000,
    })
  }
  const httpStatusCode = error.response.status
  const customCode = error.response.data && error.response.data.code
  const httpStatusCodeMsg = HTTP_STATUS_CODE_ERROR_MESSAGE[httpStatusCode]
  const customCodeMsg = CUSTOM_CODE_ERROR_MESSAGE[customCode]

  // 에러 핸들링을 axios interceptor에서 처리하지 않는 경우
  if (customCodeMsg && customCodeMsg.directManage) {
    return Promise.reject(error)
  }

  // 커스텀 코드와 HTTP 상태코드의 값에 따른 기본값들을 정의한다.
  const displayMsg = httpStatusCodeMsg ? httpStatusCodeMsg.message : '에러가 발생하였습니다.'
  const displayCode = customCode || httpStatusCode
  Message({
    message: (customCodeMsg || {}).message || `[${displayCode}] ${displayMsg}`,
    type: (customCodeMsg || {}).type || (httpStatusCodeMsg || {}).type || 'error',
    duration: 2000,
  })
  return Promise.reject(error)
}

export async function getDevPlayUser(env, gameCode, queryType, queryId) {
  const client = await getClient(env)
  const resp = (await client.get(`/v1/games/${gameCode}/player/${queryType}/${queryId}`)).data
  return camelcaseKeysDeep(resp)
}

export async function getDormantUser(env, gameCode, queryType, queryId) {
  const client = await getClient(env)
  const resp = (await client.get(`/v1/games/${gameCode}/dormant/${queryType}/${queryId}`)).data
  return camelcaseKeysDeep(resp)
}

export async function deleteGamePlayer(env, gameCode, mid) {
  const client = await getClient(env)
  return (await client.delete(`/v1/games/${gameCode}/player/mid/${mid}`)).data
}

export async function unlinkIDP(env, gameCode, email, loginType) {
  const client = await getClient(env)
  return (await client.post(`/v1/games/${gameCode}/member/${email}/unlink/${loginType}`, {})).data
}

export async function blockUser(env, gameCode, mid, reason, untilBlockDt) {
  const client = await getClient(env)
  const resp = await client.post(`/v1/games/${gameCode}/player/mid/${mid}/block`, {
    until_dt: untilBlockDt,
    cause: reason,
  })
  return resp.data
}

export async function unblockUser(env, gameCode, mid, cause) {
  const client = await getClient(env)
  const resp = await client.post(`/v1/games/${gameCode}/player/mid/${mid}/unblock`, {
    cause,
  })
  return resp.data
}

export async function revokeRefreshToken(env, gameCode, mid) {
  const client = await getClient(env)
  const resp = await client.delete(`v1/games/${gameCode}/player/mid/${mid}/refreshtoken`)
  return resp.data
}

export async function getTranslation(env, gameCode, key) {
  const client = await getClient(env)
  const resp = await client.get(`/v1/games/${gameCode}/translation/${key}`)
  return camelcaseKeysDeep(resp.data)
}

export async function postGeneratePromotionImageUploadUrl(env, gameCode, filename) {
  const client = await getClient(env)
  const resp = await client.post(`/v1/games/${gameCode}/promotion/image-upload-url`, {
    filename,
  })
  return camelcaseKeysDeep(resp.data)
}

export async function uploadToS3(url, file) {
  const res = await axios.create().put(url, file, {
    headers: {
      'Content-Type': 'application/octet-stream',
      'x-amz-acl': 'public-read',
    },
  })
  return camelcaseKeysDeep(res.data)
}

export async function getPromotions(env, gameCode) {
  const client = await getClient(env)
  const resp = await client.get(`/v1/games/${gameCode}/promotion`)
  return camelcaseKeysDeep(resp.data)
}

export async function getPromotion(env, gameCode, promotionId) {
  const client = await getClient(env)
  const resp = await client.get(`/v1/games/${gameCode}/promotion/${promotionId}`)
  return camelcaseKeysDeep(resp.data)
}

export async function postPromotion(env, gameCode, reqBody) {
  const client = await getClient(env)
  const resp = await client.post(`/v1/games/${gameCode}/promotion`, decamelizeKeysDeep(reqBody))
  return camelcaseKeysDeep(resp.data)
}

export async function putPromotion(env, gameCode, promotionId, reqBody) {
  const client = await getClient(env)
  const resp = await client.put(
    `/v1/games/${gameCode}/promotion/${promotionId}`,
    decamelizeKeysDeep(reqBody),
  )
  return camelcaseKeysDeep(resp.data)
}

export async function previewPromotion(env, gameCode, reqBody) {
  const client = await getClient(env)
  const resp = await client.post(`/v1/games/${gameCode}/promotion/preview`, reqBody)
  return resp.data
}

export async function deleteMembers(env, gameCode, pattern) {
  const client = await getClient(env)
  await client.post(`/v1/games/${gameCode}/qa/member/${pattern}/delete`)
}

export async function getRefunds(env, gameCode, searchOptions, pageOptions) {
  const client = await getClient(env)
  const endpoint = `v1/games/${gameCode}/refunds`
  const makeQueryParams = function(opt) {
    const decamelized = decamelizeKeysDeep(opt)
    const params = Object.entries(decamelized)
      .map(d => `${d[0]}=${d[1]}`)
      .join('&')
    return params
  }
  const queryParams = makeQueryParams(searchOptions) + '&' + makeQueryParams(pageOptions)
  const resp = (await client.get(`${endpoint}?${queryParams}`)).data
  return camelcaseKeysDeep(resp)
}

export async function postSubscriptionList(env, gameCode, mid) {
  const client = await getClient(env)
  const resp = await client.post(`v1/games/${gameCode}/subscriptions/list`, { mid: mid })

  return camelcaseKeysDeep(resp)
}

export async function postSubscriptionOrders(env, gameCode, mid, subscriptionId) {
  const client = await getClient(env)
  const resp = await client.post(`v1/games/${gameCode}/subscriptions/orders`, {
    mid: mid,
    subscription_id: subscriptionId,
  })

  return camelcaseKeysDeep(resp)
}

export async function recoverGuest(env, gameCode, guestMid, email) {
  const client = await getClient(env)
  await client.post(`/v1/games/${gameCode}/player/mid/${guestMid}/recovery`, { email })
}

export async function recoverPlayer(env, gameCode, email, mid) {
  const client = await getClient(env)
  await client.post(`/v1/games/${gameCode}/player/mid/${mid}/recovery-player`, {
    email,
  })
}

export async function resetPassword(env, gameCode, email) {
  const client = await getClient(env)
  const { data } = await client.post(`/v1/games/${gameCode}/member/${email}/reset_password`)
  return camelcaseKeysDeep(data)
}

export async function changeEmail(env, gameCode, email, wantedEmail) {
  const client = await getClient(env)
  await client.post(
    `/v1/games/${gameCode}/member/${email}/change_email`,
    decamelizeKeysDeep({ email, wantedEmail }),
  )
}

export async function getEmailBounce(env, gameCode, email) {
  const client = await getClient(env)
  const { data } = await client.get(`/v1/games/${gameCode}/member/${email}/bounce`)
  return camelcaseKeysDeep(data)
}

export async function deleteEmailBounce(env, gameCode, email) {
  const client = await getClient(env)
  await client.delete(`/v1/games/${gameCode}/member/${email}/bounce`)
}

export async function getMaintenanceExtra(env, gameCode, key) {
  const client = await getClient(env)
  const { data } = await client.get(`/v1/games/${gameCode}/maintenance/extra/${key}`)
  return camelcaseKeysDeep(data)
}
