import axios from 'axios'

export async function getNotices(gameId) {
  const params = { game: gameId }
  return (await axios.get('/portal/notices/', { params })).data
}

export async function createNotice(data) {
  data['version'] = data.versions ? data.versions.join(',') : ''
  data['target_languages'] = data.languages.join(',')

  return (await axios.post('/portal/notices/', data)).data
}

export async function updateNotice(data) {
  data['version'] = data.versions ? data.versions.join(',') : ''
  data['target_languages'] = data.languages == null ? null : data.languages.join(',')

  return (await axios.patch(`/portal/notices/${data.id}/`, data)).data
}

export async function deleteNotice(gameId, noticeId) {
  return (await axios.delete(`/portal/notices/${noticeId}/?game=${gameId}`)).data
}

const APIPathMap = {
  'image-type': '/portal/notice_custom_image_types/',
  'notice-type': '/portal/notice_types/',
  'notice-category': '/portal/notice_categories/',
}

export async function getNoticeTypes(type, gameId) {
  return (await axios.get(`${APIPathMap[type]}?game=${gameId}`)).data
}

export async function postNoticeType(type, gameId, name) {
  const data = {
    game: gameId,
    name: name,
  }
  return (await axios.post(APIPathMap[type], data)).data
}

export async function deleteNoticeType(type, id) {
  return (await axios.delete(`${APIPathMap[type]}${id}/`)).data
}
