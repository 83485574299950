import Vue from 'vue'
import * as api from '../api'

export default {
  actions: {
    async getNotices({ commit }, gameId) {
      const notices = await api.getNotices(gameId)
      commit('updateNotices', { gameId, notices })
    },
    async createNotice({ commit }, data) {
      const notice = await api.createNotice(data)
      commit('updateNotices', { gameId: data.game, notices: [notice] })
    },
    async updateNotice({ commit }, data) {
      const notice = await api.updateNotice(data)
      commit('updateNotices', { gameId: data.game, notices: [notice] })
    },
    async deleteNotice({ commit }, { gameId, noticeId }) {
      await api.deleteNotice(gameId, noticeId)
      commit('deleteNotice', { gameId, noticeId })
    },
    async getNoticeTypes({ commit }, { type, gameId }) {
      const types = await api.getNoticeTypes(type, gameId)
      commit('updateNoticeTypes', { type, gameId, types })
    },
    async postNoticeType({ commit }, { type, gameId, name }) {
      const newType = await api.postNoticeType(type, gameId, name)
      commit('updateNoticeTypes', { type, gameId, types: [newType] })
    },
    async deleteNoticeType({ commit }, { type, gameId, typeId }) {
      await api.deleteNoticeType(type, typeId)
      commit('deleteNoticeType', { type, gameId, typeId })
    },
  },
  state: {
    notices: {},
    types: {},
    categories: {},
    customImageTypes: {},
  },
  mutations: {
    updateNotices(state, { gameId, notices }) {
      // 키가 없을경우 새로 생성함
      if (state.notices[gameId] === undefined) {
        Vue.set(state.notices, gameId, {})
      }

      // Vue.set()이 너무 많이 호출되는것을 막기 위해 변경된 어트리뷰트가
      // 무엇무엇이 있는지 찾아 한번에 업데이트해준다
      const newNoticesObject = {}
      for (const notice of notices) {
        // 두 값이 다른경우 새 값으로 업데이트해줌
        if (!Object.is(state.notices[gameId][notice.id], notice)) {
          newNoticesObject[notice.id] = notice
        }
      }
      state.notices[gameId] = Object.assign({}, state.notices[gameId], newNoticesObject)
    },
    deleteNotice(state, { gameId, noticeId }) {
      Vue.delete(state.notices[gameId], noticeId)
    },
    updateNoticeTypes(state, { type, gameId, types }) {
      let store
      switch (type) {
        case 'notice-type':
          store = state.types
          break
        case 'notice-category':
          store = state.categories
          break
        case 'image-type':
          store = state.customImageTypes
          break
      }
      if (store[gameId] === undefined) {
        Vue.set(store, gameId, [])
      }
      const newTypesObject = {}
      for (const t of types) {
        if (!Object.is(store[gameId][t.id], t)) {
          newTypesObject[t.id] = t
        }
      }
      store[gameId] = Object.assign({}, store[gameId], newTypesObject)
    },
    deleteNoticeType(state, { type, gameId, typeId }) {
      let store
      switch (type) {
        case 'notice-type':
          store = state.types
          break
        case 'notice-category':
          store = state.categories
          break
        case 'image-type':
          store = state.customImageTypes
          break
      }
      Vue.delete(store[gameId], typeId)
    },
  },
}
