import axios from 'axios'
import { getClient } from './reception'

export async function getEndpointsForGame({ gameId, appEnv, page, pageSize, ...restParams }) {
  const params = new URLSearchParams()
  params.set('game', gameId)

  if (appEnv) {
    params.set('app_env', appEnv)
  }
  if (page) {
    params.set('page', page)
  }
  if (pageSize) {
    params.set('page_size', pageSize)
  }
  for (const k in restParams) {
    params.set(k, restParams[k])
  }
  return (await axios.get('/portal/endpoints/', { params })).data
}

export async function getEndpoint(endpointId) {
  return (await axios.get(`/portal/endpoints/${endpointId}/`)).data
}

export async function putDefaultRouteEndpoint(
  gameCode,
  defaultRouteReceptionEnv,
  gameEndpointsArray,
  resources,
  isInReview,
  serverFarmId,
) {
  const data = {
    resource_endpoints: JSON.stringify(resources),
    is_in_review: isInReview,
    game_endpoints: JSON.stringify(gameEndpointsArray),
    server_farm: serverFarmId,
  }

  const client = await getClient(defaultRouteReceptionEnv)
  const resp = (await client.put(`/v1/games/${gameCode}/defaultroute`, data)).data
  return resp
}

export async function getDefaultRouteEndpointsForGame(gameCode) {
  // 모든 환경의 reception에 요청해서, 기본 엔드포인트를 한번에 모두 가져온다.
  const appEnvDict = { SANDBOX: 'dev', STG: 'qa_stg', PROD: 'prod' }
  const receptionEnv = ['SANDBOX', 'STG', 'PROD']
  const result = []
  for (const env of receptionEnv) {
    const client = await getClient(env)
    const resp = (await client.get(`/v1/games/${gameCode}/defaultroute`)).data
    // 해당 환경에서 기본 엔드포인트가 존재하지 않을 경우, reception에서 빈 값을 반환한다.
    if (resp.routes !== '') {
      result.push(
        Object.assign(
          {
            env: env === 'SANDBOX' ? 'dev' : env.toLowerCase(),
            app_env: appEnvDict[env],
          },
          resp,
        ),
      )
    }
  }
  return result
}

export async function postEndpoint(
  gameId,
  appEnv,
  platform,
  store,
  version,
  mid,
  deviceId,
  deviceIdLabel,
  ipAddress,
  serverFarm,
  resources,
  env,
  gameEndpoints,
  remoteLoggingEndpoint,
  release,
  isInReview,
) {
  const data = {
    game: gameId,
    app_env: appEnv,
    env: env,
    remote_logging_endpoint: remoteLoggingEndpoint,
    is_in_review: isInReview,
  }
  if (platform) {
    data['platform'] = platform
  }
  if (store) {
    data['store'] = store
  }
  if (version) {
    data['version'] = version
  }
  if (mid) {
    data['mid'] = mid
  }
  if (deviceId) {
    data['device_id'] = deviceId
  }
  if (deviceIdLabel) {
    data['device_id_label'] = deviceIdLabel
  }
  if (ipAddress) {
    data['ip_address'] = ipAddress
  }
  if (serverFarm) {
    data['server_farm'] = serverFarm.id
  }
  if (resources) {
    data['resources'] = resources
  }
  if (gameEndpoints) {
    data['game_endpoints'] = gameEndpoints
  }
  if (release) {
    data['release'] = release.id
  }
  return (await axios.post('/portal/endpoints/', data)).data
}

export async function deleteEndpoint(endpointId) {
  return (await axios.delete(`/portal/endpoints/${endpointId}/`)).data
}

export async function getVersionSuggestion(gameId, platform) {
  return (await axios.get(`/portal/endpoints/version_suggestion/${gameId}/?platform=${platform}`))
    .data
}
