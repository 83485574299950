const AnalyticsReportList = () =>
  import(
    /* webpackChunkName: "component_analytics_report" */ 'client/components/Games/AnalyticsReport/AnalyticsReportList'
  )
const AnalyticsReportNew = () =>
  import(
    /* webpackChunkName: "component_analytics_report" */ 'client/components/Games/AnalyticsReport/AnalyticsReportNew'
  )
const AnalyticsReportEdit = () =>
  import(
    /* webpackChunkName: "component_analytics_report" */ 'client/components/Games/AnalyticsReport/AnalyticsReportEdit'
  )
const AnalyticsReport = () =>
  import(
    /* webpackChunkName: "component_analytics_report" */ 'client/components/Games/AnalyticsReport/AnalyticsReportView'
  )
import { INQUERY } from 'client/components/Layout/MenuGroups/consts'

const routes = [
  {
    path: '/games/:gameCode/manage-analytics-reports',
    component: AnalyticsReportList,
    name: 'analytics-report-list',
    meta: {
      title: 'message.HomeLabelKpireportinq',
      group: INQUERY,
    },
  },
  {
    path: '/games/:gameCode/manage-analytics-reports/new',
    component: AnalyticsReportNew,
    name: 'analytics-report-new',
    meta: {
      title: 'message.HomeLabelKpireportreg',
      group: INQUERY,
    },
  },
  {
    path: '/games/:gameCode/manage-analytics-reports/:reportKey/edit',
    component: AnalyticsReportEdit,
    name: 'analytics-report-edit',
    meta: {
      title: 'message.HomeLabelKpireportmod',
      group: INQUERY,
    },
  },
  {
    path: '/games/:gameCode/analytics-reports/:reportKey',
    component: AnalyticsReport,
    name: 'analytics-report-view',
    meta: {
      title: 'message.HomeLabelKpireport',
      group: INQUERY,
    },
  },
]

export default routes
