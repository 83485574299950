import axios from 'axios'

export async function uploadWordFilterXlsx(file, desc, game = null) {
  const url = '/portal/wordfilter/dict/'
  const formData = new FormData()
  formData.append('dict', file)
  formData.append('desc', desc)
  if (game !== null) formData.append('game', game)

  return (await axios.post(url, formData)).data
}

export async function getWordFilterUpdateHistories(game = null) {
  const url = '/portal/wordfilter/history/'
  let params = { limit: 5000 }
  if (game !== null) params['game'] = game

  return (await axios.get(url, { params: params })).data.results
}

export async function getWordFilterUpdateHistory(req_uuid, game = null) {
  const url = '/portal/wordfilter/history/'
  let params = {}
  if (game !== null) params['game'] = game

  return (await axios.get(url + req_uuid + '/', { params: params })).data
}

export async function downloadWordFilterXlsx(req_uuid, game = null) {
  const url = '/portal/wordfilter/dict/'
  let params = { req_uuid: req_uuid }
  if (game !== null) params['game'] = game

  return (await axios.get(url, { params: params })).data.signed_url
}

export async function applyWordFilterUpdate(req_uuid, game = null) {
  const url = '/portal/wordfilter/dict/'
  let data = { req_uuid: req_uuid }
  if (game !== null) data['game'] = game

  return (await axios.patch(url, data)).data
}
