import { getApiVersion } from 'client/api/etc'

export default {
  state: {
    lastCommit: '',
    lastCommitter: '',
    buildDate: '',
    env: '',
  },
  actions: {
    async getApiVersion({ commit }) {
      const apiVersion = await getApiVersion()
      commit('setApiVersion', apiVersion)
    },
  },
  mutations: {
    setApiVersion(state, { last_commit, last_committer, build_date, env }) {
      state.lastCommit = last_commit
      state.lastCommitter = last_committer
      state.buildDate = build_date
      state.env = env
    },
  },
}
