import * as api from '../api'
import Vue from 'vue'

export default {
  actions: {
    async getUser({ state, commit }, { userId }) {
      if (state.users[userId] == null) {
        const user = await api.getUser(userId)
        commit('updateUser', { user })
        return user
      }
      return state.users[userId]
    },
  },
  state: {
    users: {},
  },
  mutations: {
    updateUser(state, { user }) {
      Vue.set(state.users, user.id, user)
    },
  },
}
