import * as api from '../api'
import Vue from 'vue'

export default {
  actions: {
    async getEndpointsForGame({ commit }, { gameId }) {
      const endpoints = await api.getEndpointsForGame({ gameId })
      for (const endpoint of endpoints) {
        commit('updateEndpoint', { gameId, endpoint })
      }
    },
    async getEndpoint({ commit }, { endpointId }) {
      const endpoint = await api.getEndpoint(endpointId)
      commit('updateEndpoint', { gameId: endpoint.game, endpoint })
    },
    async getDefaultRouteEndpointsForGame({ commit }, { gameId, gameCode }) {
      const defaultRouteEndpoints = await api.getDefaultRouteEndpointsForGame(gameCode)
      for (const defaultRouteEndpoint of defaultRouteEndpoints) {
        commit('updateDefaultRouteEndpoint', { gameId, defaultRouteEndpoint })
      }
    },
    async putDefaultRouteEndpoint(
      { commit },
      {
        game,
        appEnv,
        env,
        defaultRouteReceptionEnv,
        gameEndpointsArray,
        resources,
        isInReview,
        serverFarmId,
      },
    ) {
      const gameId = game.id
      const gameCode = game.game_code
      const defaultRouteEndpoint = await api.putDefaultRouteEndpoint(
        gameCode,
        defaultRouteReceptionEnv,
        gameEndpointsArray,
        resources,
        isInReview,
        serverFarmId,
      )
      defaultRouteEndpoint.env = env
      defaultRouteEndpoint.app_env = appEnv
      commit('updateDefaultRouteEndpoint', { gameId, defaultRouteEndpoint })
    },
    async postEndpoint(
      { commit },
      {
        game,
        appEnv,
        platform,
        store,
        version,
        mid,
        deviceId,
        deviceIdLabel,
        ipAddress,
        env,
        gameEndpoints,
        serverFarm,
        resources,
        remoteLoggingEndpoint,
        release,
        isInReview,
      },
    ) {
      const gameId = game.id
      const endpoint = await api.postEndpoint(
        gameId,
        appEnv,
        platform,
        store,
        version,
        mid,
        deviceId,
        deviceIdLabel,
        ipAddress,
        serverFarm,
        resources,
        env,
        gameEndpoints,
        remoteLoggingEndpoint,
        release,
        isInReview,
      )
      commit('updateEndpoint', { gameId, endpoint })
      return endpoint
    },
    async deleteEndpoint({ commit }, { gameId, endpointId }) {
      await api.deleteEndpoint(endpointId)
      commit('deleteEndpoint', { gameId, endpointId })
    },
  },
  state: {
    endpoints: {},
    defaultRouteEndpoints: {},
  },
  mutations: {
    updateEndpoint(state, { gameId, endpoint }) {
      if (state.endpoints[gameId] == null) {
        Vue.set(state.endpoints, gameId, {})
      }
      Vue.set(state.endpoints[gameId], endpoint.id, endpoint)
    },
    deleteEndpoint(state, { gameId, endpointId }) {
      Vue.delete(state.endpoints[gameId], endpointId)
    },
    updateDefaultRouteEndpoint(state, { gameId, defaultRouteEndpoint }) {
      if (state.defaultRouteEndpoints[gameId] == null) {
        Vue.set(state.defaultRouteEndpoints, gameId, {})
      }

      const routes = JSON.parse(defaultRouteEndpoint.routes)
      const gameEndpointDict = {
        env: ['stg', 'qa'].includes(defaultRouteEndpoint.env) ? 'qa_stg' : defaultRouteEndpoint.env,
      }
      // (XXX): reception과 portal이 엔드포인트를 저장하는 포맷이 달라서 이를 변환해주는 작업이 필요하다.
      for (let i = 0; i < routes.length; i = i + 2) {
        gameEndpointDict[routes[i]] = routes[i + 1]
      }
      defaultRouteEndpoint.routes = JSON.stringify(gameEndpointDict)
      Vue.set(state.defaultRouteEndpoints[gameId], gameEndpointDict.env, defaultRouteEndpoint)
    },
  },
}
