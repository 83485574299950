import axios from 'axios'
import camelcaseKeysDeep from 'camelcase-keys-deep'

/* Document Versions */

export async function loadDocumentVersions(documentCode) {
  return (await axios.get(`/portal/documents/${documentCode}/versions/`)).data
}

export async function loadDocumentVersion(documentCode, version) {
  return (await axios.get(`/portal/documents/${documentCode}/versions/${version}/`)).data
}

export async function setDocumentVersionVisibility(documentCode, version, isPublic = true) {
  return (
    await axios.patch(`/portal/documents/${documentCode}/versions/${version}/`, {
      public: isPublic,
    })
  ).data
}

export async function createVersion(documentCode, version, from) {
  return (
    await axios.post(`/portal/documents/${documentCode}/versions/`, {
      version,
      public: false,
      from,
    })
  ).data
}

/* Documents */

export async function loadDocuments(params) {
  return (await axios.get('/portal/documents/', { params })).data
}

export async function loadDocument(code, params) {
  return (await axios.get(`/portal/documents/${code}/`, { params })).data
}

export async function createDocument({ code, parent, title, version, isPublic }) {
  return (
    await axios.post('/portal/documents/', {
      code,
      title,
      parent,
      version,
      public: isPublic,
      order: 0,
    })
  ).data
}

export async function setDocumentVisibility(code, isPublic = true) {
  return (
    await axios.patch(`/portal/documents/${code}/`, {
      public: isPublic,
    })
  ).data
}

export async function updateDocument(code, partialDocument) {
  return (await axios.patch(`/portal/documents/${code}/`, partialDocument)).data
}

/* Document Contents */
export async function createOrUpdateDocumentContent(
  code,
  version,
  { content, changeLog, isDraft = false },
) {
  return (
    await axios.post(`/portal/documents/${code}/versions/${version}/contents/`, {
      content,
      ...(changeLog || changeLog !== '' ? { change_log: changeLog } : {}),
      is_draft: isDraft,
    })
  ).data
}

/* Document files */

export async function loadDocumentFileList(page = 1, pgSize = 15) {
  return (await axios.get(`/portal/document_files/?page=${page}&page-size=${pgSize}`)).data
}

/* Document search */

export async function searchDocument(search = '', fromAllVersions = false, page = 1, pgSize = 15) {
  const res = (
    await axios.get('/portal/documents/search/', {
      params: {
        s: search,
        a: fromAllVersions,
        page: page,
        'page-size': pgSize,
      },
    })
  ).data
  return camelcaseKeysDeep(res)
}
