import * as api from '../api'
import Vue from 'vue'

export default {
  state: {
    events: {},
  },

  actions: {
    async searchCouponEvents({ commit }, { env, gameCode, options }) {
      const { events, nextPageToken } = await api.searchCouponEvents(env, gameCode, options)
      commit('setCouponEvents', { gameCode, env, events })
      return {
        events: events,
        nextPageToken: nextPageToken,
      }
    },
    async getCouponEvent({ commit }, { env, eventId }) {
      const event = await api.getCouponEvent(env, eventId)
      commit('setCouponEvents', { gameCode: event.gameCode, env: env, events: [event] })
      return event
    },
    async getRandomTypeEventUsage({ commit }, { env, event }) {
      return api.getRandomTypeEventUsage(env, event.id)
    },
    async getWordTypeEventUsage({ commit }, { env, event }) {
      return api.getWordTypeEventUsage(env, event.id)
    },
    async createCouponEvent({ dispatch }, { env, event, couponCode }) {
      const newEvent = await api.createCouponEvent(env, event, couponCode)
      dispatch('getCouponEvent', { env, eventId: newEvent.id })
      return newEvent
    },
    async updateCouponEvent({ dispatch }, { env, eventId, eventUpdate }) {
      await api.updateCouponEvent(env, eventId, eventUpdate)
      dispatch('getCouponEvent', { env, eventId })
    },
    async issueAdditionalCoupon({ dispatch }, { env, eventId, amount, comment }) {
      await api.issueAdditionalCoupon(env, eventId, amount, comment)
      dispatch('getCouponEvent', { env, eventId })
    },
  },
  mutations: {
    setCouponEvents(state, { gameCode, env, events }) {
      if (state.events[gameCode] === undefined) {
        Vue.set(state.events, gameCode, {})
      }
      if (state.events[gameCode][env] === undefined) {
        Vue.set(state.events[gameCode], env, {})
      }

      events.forEach(event => Vue.set(state.events[gameCode][env], event.id, event))
    },
  },
}
