// source: devplay/coupon/internals/coupon_resources.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.devplay.coupon.internals.Country', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.Coupon', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.CouponType', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.CouponUsage', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.CouponUsageWithEvent', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.Event', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.Issue', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.Issue.IssueStatus', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.Reward', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.TranslatedMessage', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.TranslatedMessage.TranslationMessageType', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.TranslatedMessage.translation_text', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devplay.coupon.internals.Event.repeatedFields_, null);
};
goog.inherits(proto.devplay.coupon.internals.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.Event.displayName = 'proto.devplay.coupon.internals.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.TranslatedMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devplay.coupon.internals.TranslatedMessage.repeatedFields_, null);
};
goog.inherits(proto.devplay.coupon.internals.TranslatedMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.TranslatedMessage.displayName = 'proto.devplay.coupon.internals.TranslatedMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.TranslatedMessage.translation_text, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.TranslatedMessage.translation_text.displayName = 'proto.devplay.coupon.internals.TranslatedMessage.translation_text';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.Issue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.Issue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.Issue.displayName = 'proto.devplay.coupon.internals.Issue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.Country = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.Country, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.Country.displayName = 'proto.devplay.coupon.internals.Country';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.Reward = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.Reward, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.Reward.displayName = 'proto.devplay.coupon.internals.Reward';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.Coupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.Coupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.Coupon.displayName = 'proto.devplay.coupon.internals.Coupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.CouponUsage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.CouponUsage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.CouponUsage.displayName = 'proto.devplay.coupon.internals.CouponUsage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.CouponUsageWithEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.CouponUsageWithEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.CouponUsageWithEvent.displayName = 'proto.devplay.coupon.internals.CouponUsageWithEvent';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devplay.coupon.internals.Event.repeatedFields_ = [9,10,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    couponName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    couponType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numberOfUsesPerMid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hasTargetCountries: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    targetCountriesList: jspb.Message.toObjectList(msg.getTargetCountriesList(),
    proto.devplay.coupon.internals.Country.toObject, includeInstance),
    rewardsList: jspb.Message.toObjectList(msg.getRewardsList(),
    proto.devplay.coupon.internals.Reward.toObject, includeInstance),
    inboxId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    titleMessage: (f = msg.getTitleMessage()) && proto.devplay.coupon.internals.TranslatedMessage.toObject(includeInstance, f),
    bodyMessage: (f = msg.getBodyMessage()) && proto.devplay.coupon.internals.TranslatedMessage.toObject(includeInstance, f),
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.devplay.coupon.internals.Issue.toObject, includeInstance),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.Event}
 */
proto.devplay.coupon.internals.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.Event;
  return proto.devplay.coupon.internals.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.Event}
 */
proto.devplay.coupon.internals.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponName(value);
      break;
    case 4:
      var value = /** @type {!proto.devplay.coupon.internals.CouponType} */ (reader.readEnum());
      msg.setCouponType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfUsesPerMid(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasTargetCountries(value);
      break;
    case 9:
      var value = new proto.devplay.coupon.internals.Country;
      reader.readMessage(value,proto.devplay.coupon.internals.Country.deserializeBinaryFromReader);
      msg.addTargetCountries(value);
      break;
    case 10:
      var value = new proto.devplay.coupon.internals.Reward;
      reader.readMessage(value,proto.devplay.coupon.internals.Reward.deserializeBinaryFromReader);
      msg.addRewards(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInboxId(value);
      break;
    case 12:
      var value = new proto.devplay.coupon.internals.TranslatedMessage;
      reader.readMessage(value,proto.devplay.coupon.internals.TranslatedMessage.deserializeBinaryFromReader);
      msg.setTitleMessage(value);
      break;
    case 13:
      var value = new proto.devplay.coupon.internals.TranslatedMessage;
      reader.readMessage(value,proto.devplay.coupon.internals.TranslatedMessage.deserializeBinaryFromReader);
      msg.setBodyMessage(value);
      break;
    case 14:
      var value = new proto.devplay.coupon.internals.Issue;
      reader.readMessage(value,proto.devplay.coupon.internals.Issue.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouponName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCouponType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getNumberOfUsesPerMid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHasTargetCountries();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTargetCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.devplay.coupon.internals.Country.serializeBinaryToWriter
    );
  }
  f = message.getRewardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.devplay.coupon.internals.Reward.serializeBinaryToWriter
    );
  }
  f = message.getInboxId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTitleMessage();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.devplay.coupon.internals.TranslatedMessage.serializeBinaryToWriter
    );
  }
  f = message.getBodyMessage();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.devplay.coupon.internals.TranslatedMessage.serializeBinaryToWriter
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.devplay.coupon.internals.Issue.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.Event.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_code = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.Event.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string coupon_name = 3;
 * @return {string}
 */
proto.devplay.coupon.internals.Event.prototype.getCouponName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.setCouponName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CouponType coupon_type = 4;
 * @return {!proto.devplay.coupon.internals.CouponType}
 */
proto.devplay.coupon.internals.Event.prototype.getCouponType = function() {
  return /** @type {!proto.devplay.coupon.internals.CouponType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.devplay.coupon.internals.CouponType} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.setCouponType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 number_of_uses_per_mid = 5;
 * @return {number}
 */
proto.devplay.coupon.internals.Event.prototype.getNumberOfUsesPerMid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.setNumberOfUsesPerMid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.devplay.coupon.internals.Event.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
*/
proto.devplay.coupon.internals.Event.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.Event.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.devplay.coupon.internals.Event.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
*/
proto.devplay.coupon.internals.Event.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.Event.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool has_target_countries = 8;
 * @return {boolean}
 */
proto.devplay.coupon.internals.Event.prototype.getHasTargetCountries = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.setHasTargetCountries = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated Country target_countries = 9;
 * @return {!Array<!proto.devplay.coupon.internals.Country>}
 */
proto.devplay.coupon.internals.Event.prototype.getTargetCountriesList = function() {
  return /** @type{!Array<!proto.devplay.coupon.internals.Country>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devplay.coupon.internals.Country, 9));
};


/**
 * @param {!Array<!proto.devplay.coupon.internals.Country>} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
*/
proto.devplay.coupon.internals.Event.prototype.setTargetCountriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.devplay.coupon.internals.Country=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.Country}
 */
proto.devplay.coupon.internals.Event.prototype.addTargetCountries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.devplay.coupon.internals.Country, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.clearTargetCountriesList = function() {
  return this.setTargetCountriesList([]);
};


/**
 * repeated Reward rewards = 10;
 * @return {!Array<!proto.devplay.coupon.internals.Reward>}
 */
proto.devplay.coupon.internals.Event.prototype.getRewardsList = function() {
  return /** @type{!Array<!proto.devplay.coupon.internals.Reward>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devplay.coupon.internals.Reward, 10));
};


/**
 * @param {!Array<!proto.devplay.coupon.internals.Reward>} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
*/
proto.devplay.coupon.internals.Event.prototype.setRewardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.devplay.coupon.internals.Reward=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.Reward}
 */
proto.devplay.coupon.internals.Event.prototype.addRewards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.devplay.coupon.internals.Reward, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.clearRewardsList = function() {
  return this.setRewardsList([]);
};


/**
 * optional string inbox_id = 11;
 * @return {string}
 */
proto.devplay.coupon.internals.Event.prototype.getInboxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.setInboxId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional TranslatedMessage title_message = 12;
 * @return {?proto.devplay.coupon.internals.TranslatedMessage}
 */
proto.devplay.coupon.internals.Event.prototype.getTitleMessage = function() {
  return /** @type{?proto.devplay.coupon.internals.TranslatedMessage} */ (
    jspb.Message.getWrapperField(this, proto.devplay.coupon.internals.TranslatedMessage, 12));
};


/**
 * @param {?proto.devplay.coupon.internals.TranslatedMessage|undefined} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
*/
proto.devplay.coupon.internals.Event.prototype.setTitleMessage = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.clearTitleMessage = function() {
  return this.setTitleMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.Event.prototype.hasTitleMessage = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional TranslatedMessage body_message = 13;
 * @return {?proto.devplay.coupon.internals.TranslatedMessage}
 */
proto.devplay.coupon.internals.Event.prototype.getBodyMessage = function() {
  return /** @type{?proto.devplay.coupon.internals.TranslatedMessage} */ (
    jspb.Message.getWrapperField(this, proto.devplay.coupon.internals.TranslatedMessage, 13));
};


/**
 * @param {?proto.devplay.coupon.internals.TranslatedMessage|undefined} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
*/
proto.devplay.coupon.internals.Event.prototype.setBodyMessage = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.clearBodyMessage = function() {
  return this.setBodyMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.Event.prototype.hasBodyMessage = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated Issue issues = 14;
 * @return {!Array<!proto.devplay.coupon.internals.Issue>}
 */
proto.devplay.coupon.internals.Event.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.devplay.coupon.internals.Issue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devplay.coupon.internals.Issue, 14));
};


/**
 * @param {!Array<!proto.devplay.coupon.internals.Issue>} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
*/
proto.devplay.coupon.internals.Event.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.devplay.coupon.internals.Issue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.Issue}
 */
proto.devplay.coupon.internals.Event.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.devplay.coupon.internals.Issue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};


/**
 * optional google.protobuf.Timestamp create_time = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.devplay.coupon.internals.Event.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.devplay.coupon.internals.Event} returns this
*/
proto.devplay.coupon.internals.Event.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.Event} returns this
 */
proto.devplay.coupon.internals.Event.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.Event.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devplay.coupon.internals.TranslatedMessage.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.TranslatedMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.TranslatedMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.TranslatedMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    translationMessageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    translationKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    translationTextsList: jspb.Message.toObjectList(msg.getTranslationTextsList(),
    proto.devplay.coupon.internals.TranslatedMessage.translation_text.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.TranslatedMessage}
 */
proto.devplay.coupon.internals.TranslatedMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.TranslatedMessage;
  return proto.devplay.coupon.internals.TranslatedMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.TranslatedMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.TranslatedMessage}
 */
proto.devplay.coupon.internals.TranslatedMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.devplay.coupon.internals.TranslatedMessage.TranslationMessageType} */ (reader.readEnum());
      msg.setTranslationMessageType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranslationKey(value);
      break;
    case 3:
      var value = new proto.devplay.coupon.internals.TranslatedMessage.translation_text;
      reader.readMessage(value,proto.devplay.coupon.internals.TranslatedMessage.translation_text.deserializeBinaryFromReader);
      msg.addTranslationTexts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.TranslatedMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.TranslatedMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.TranslatedMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTranslationMessageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTranslationKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTranslationTextsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.devplay.coupon.internals.TranslatedMessage.translation_text.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.devplay.coupon.internals.TranslatedMessage.TranslationMessageType = {
  TRANSLATION_MESSAGE_TYPE_UNSPECIFIED: 0,
  TRANSLATION_MESSAGE_TYPE_TITLE: 1,
  TRANSLATION_MESSAGE_TYPE_BODY: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.TranslatedMessage.translation_text.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.TranslatedMessage.translation_text} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.toObject = function(includeInstance, msg) {
  var f, obj = {
    languageCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.TranslatedMessage.translation_text}
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.TranslatedMessage.translation_text;
  return proto.devplay.coupon.internals.TranslatedMessage.translation_text.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.TranslatedMessage.translation_text} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.TranslatedMessage.translation_text}
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.TranslatedMessage.translation_text.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.TranslatedMessage.translation_text} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string language_code = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.TranslatedMessage.translation_text} returns this
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.TranslatedMessage.translation_text} returns this
 */
proto.devplay.coupon.internals.TranslatedMessage.translation_text.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TranslationMessageType translation_message_type = 1;
 * @return {!proto.devplay.coupon.internals.TranslatedMessage.TranslationMessageType}
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.getTranslationMessageType = function() {
  return /** @type {!proto.devplay.coupon.internals.TranslatedMessage.TranslationMessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.devplay.coupon.internals.TranslatedMessage.TranslationMessageType} value
 * @return {!proto.devplay.coupon.internals.TranslatedMessage} returns this
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.setTranslationMessageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string translation_key = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.getTranslationKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.TranslatedMessage} returns this
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.setTranslationKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated translation_text translation_texts = 3;
 * @return {!Array<!proto.devplay.coupon.internals.TranslatedMessage.translation_text>}
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.getTranslationTextsList = function() {
  return /** @type{!Array<!proto.devplay.coupon.internals.TranslatedMessage.translation_text>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devplay.coupon.internals.TranslatedMessage.translation_text, 3));
};


/**
 * @param {!Array<!proto.devplay.coupon.internals.TranslatedMessage.translation_text>} value
 * @return {!proto.devplay.coupon.internals.TranslatedMessage} returns this
*/
proto.devplay.coupon.internals.TranslatedMessage.prototype.setTranslationTextsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.devplay.coupon.internals.TranslatedMessage.translation_text=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.TranslatedMessage.translation_text}
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.addTranslationTexts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.devplay.coupon.internals.TranslatedMessage.translation_text, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.TranslatedMessage} returns this
 */
proto.devplay.coupon.internals.TranslatedMessage.prototype.clearTranslationTextsList = function() {
  return this.setTranslationTextsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.Issue.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.Issue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.Issue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Issue.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issueStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    couponCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    issueComment: jspb.Message.getFieldWithDefault(msg, 4, ""),
    issuer: jspb.Message.getFieldWithDefault(msg, 5, ""),
    issueTime: (f = msg.getIssueTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.Issue}
 */
proto.devplay.coupon.internals.Issue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.Issue;
  return proto.devplay.coupon.internals.Issue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.Issue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.Issue}
 */
proto.devplay.coupon.internals.Issue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.devplay.coupon.internals.Issue.IssueStatus} */ (reader.readEnum());
      msg.setIssueStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponCount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueComment(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuer(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIssueTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.Issue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.Issue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.Issue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Issue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssueStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCouponCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIssueComment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIssuer();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIssueTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.devplay.coupon.internals.Issue.IssueStatus = {
  ISSUE_STATUS_UNSPECIFIED: 0,
  ISSUE_STATUS_PROCEEDING: 1,
  ISSUE_STATUS_COMPLETE: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.Issue.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Issue} returns this
 */
proto.devplay.coupon.internals.Issue.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IssueStatus issue_status = 2;
 * @return {!proto.devplay.coupon.internals.Issue.IssueStatus}
 */
proto.devplay.coupon.internals.Issue.prototype.getIssueStatus = function() {
  return /** @type {!proto.devplay.coupon.internals.Issue.IssueStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.devplay.coupon.internals.Issue.IssueStatus} value
 * @return {!proto.devplay.coupon.internals.Issue} returns this
 */
proto.devplay.coupon.internals.Issue.prototype.setIssueStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 coupon_count = 3;
 * @return {number}
 */
proto.devplay.coupon.internals.Issue.prototype.getCouponCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.Issue} returns this
 */
proto.devplay.coupon.internals.Issue.prototype.setCouponCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string issue_comment = 4;
 * @return {string}
 */
proto.devplay.coupon.internals.Issue.prototype.getIssueComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Issue} returns this
 */
proto.devplay.coupon.internals.Issue.prototype.setIssueComment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string issuer = 5;
 * @return {string}
 */
proto.devplay.coupon.internals.Issue.prototype.getIssuer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Issue} returns this
 */
proto.devplay.coupon.internals.Issue.prototype.setIssuer = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp issue_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.devplay.coupon.internals.Issue.prototype.getIssueTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.devplay.coupon.internals.Issue} returns this
*/
proto.devplay.coupon.internals.Issue.prototype.setIssueTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.Issue} returns this
 */
proto.devplay.coupon.internals.Issue.prototype.clearIssueTime = function() {
  return this.setIssueTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.Issue.prototype.hasIssueTime = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.Country.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.Country.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.Country} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Country.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.Country}
 */
proto.devplay.coupon.internals.Country.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.Country;
  return proto.devplay.coupon.internals.Country.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.Country} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.Country}
 */
proto.devplay.coupon.internals.Country.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.Country.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.Country.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.Country} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Country.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.Country.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Country} returns this
 */
proto.devplay.coupon.internals.Country.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.Reward.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.Reward.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.Reward} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Reward.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    extra: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.Reward}
 */
proto.devplay.coupon.internals.Reward.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.Reward;
  return proto.devplay.coupon.internals.Reward.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.Reward} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.Reward}
 */
proto.devplay.coupon.internals.Reward.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtra(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.Reward.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.Reward.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.Reward} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Reward.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getExtra();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.Reward.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Reward} returns this
 */
proto.devplay.coupon.internals.Reward.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.Reward.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Reward} returns this
 */
proto.devplay.coupon.internals.Reward.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.devplay.coupon.internals.Reward.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.devplay.coupon.internals.Reward} returns this
 */
proto.devplay.coupon.internals.Reward.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string extra = 4;
 * @return {string}
 */
proto.devplay.coupon.internals.Reward.prototype.getExtra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Reward} returns this
 */
proto.devplay.coupon.internals.Reward.prototype.setExtra = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.Coupon.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.Coupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.Coupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Coupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    couponCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.Coupon}
 */
proto.devplay.coupon.internals.Coupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.Coupon;
  return proto.devplay.coupon.internals.Coupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.Coupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.Coupon}
 */
proto.devplay.coupon.internals.Coupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.Coupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.Coupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.Coupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.Coupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string game_code = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.Coupon.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Coupon} returns this
 */
proto.devplay.coupon.internals.Coupon.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string coupon_code = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.Coupon.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.Coupon} returns this
 */
proto.devplay.coupon.internals.Coupon.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.CouponUsage.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.CouponUsage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.CouponUsage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.CouponUsage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    couponCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    useTime: (f = msg.getUseTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.CouponUsage}
 */
proto.devplay.coupon.internals.CouponUsage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.CouponUsage;
  return proto.devplay.coupon.internals.CouponUsage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.CouponUsage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.CouponUsage}
 */
proto.devplay.coupon.internals.CouponUsage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMid(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUseTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.CouponUsage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.CouponUsage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.CouponUsage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.CouponUsage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUseTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.CouponUsage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.CouponUsage} returns this
 */
proto.devplay.coupon.internals.CouponUsage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_code = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.CouponUsage.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.CouponUsage} returns this
 */
proto.devplay.coupon.internals.CouponUsage.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string coupon_code = 3;
 * @return {string}
 */
proto.devplay.coupon.internals.CouponUsage.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.CouponUsage} returns this
 */
proto.devplay.coupon.internals.CouponUsage.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mid = 4;
 * @return {string}
 */
proto.devplay.coupon.internals.CouponUsage.prototype.getMid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.CouponUsage} returns this
 */
proto.devplay.coupon.internals.CouponUsage.prototype.setMid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp use_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.devplay.coupon.internals.CouponUsage.prototype.getUseTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.devplay.coupon.internals.CouponUsage} returns this
*/
proto.devplay.coupon.internals.CouponUsage.prototype.setUseTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.CouponUsage} returns this
 */
proto.devplay.coupon.internals.CouponUsage.prototype.clearUseTime = function() {
  return this.setUseTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.CouponUsage.prototype.hasUseTime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.CouponUsageWithEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.CouponUsageWithEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponUsage: (f = msg.getCouponUsage()) && proto.devplay.coupon.internals.CouponUsage.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && proto.devplay.coupon.internals.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.CouponUsageWithEvent}
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.CouponUsageWithEvent;
  return proto.devplay.coupon.internals.CouponUsageWithEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.CouponUsageWithEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.CouponUsageWithEvent}
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.devplay.coupon.internals.CouponUsage;
      reader.readMessage(value,proto.devplay.coupon.internals.CouponUsage.deserializeBinaryFromReader);
      msg.setCouponUsage(value);
      break;
    case 2:
      var value = new proto.devplay.coupon.internals.Event;
      reader.readMessage(value,proto.devplay.coupon.internals.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.CouponUsageWithEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.CouponUsageWithEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponUsage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.devplay.coupon.internals.CouponUsage.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.devplay.coupon.internals.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional CouponUsage coupon_usage = 1;
 * @return {?proto.devplay.coupon.internals.CouponUsage}
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.getCouponUsage = function() {
  return /** @type{?proto.devplay.coupon.internals.CouponUsage} */ (
    jspb.Message.getWrapperField(this, proto.devplay.coupon.internals.CouponUsage, 1));
};


/**
 * @param {?proto.devplay.coupon.internals.CouponUsage|undefined} value
 * @return {!proto.devplay.coupon.internals.CouponUsageWithEvent} returns this
*/
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.setCouponUsage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.CouponUsageWithEvent} returns this
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.clearCouponUsage = function() {
  return this.setCouponUsage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.hasCouponUsage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Event event = 2;
 * @return {?proto.devplay.coupon.internals.Event}
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.getEvent = function() {
  return /** @type{?proto.devplay.coupon.internals.Event} */ (
    jspb.Message.getWrapperField(this, proto.devplay.coupon.internals.Event, 2));
};


/**
 * @param {?proto.devplay.coupon.internals.Event|undefined} value
 * @return {!proto.devplay.coupon.internals.CouponUsageWithEvent} returns this
*/
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.CouponUsageWithEvent} returns this
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.CouponUsageWithEvent.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.devplay.coupon.internals.CouponType = {
  COUPON_TYPE_UNSPECIFIED: 0,
  COUPON_TYPE_RANDOM: 1,
  COUPON_TYPE_WORD: 2
};

goog.object.extend(exports, proto.devplay.coupon.internals);
