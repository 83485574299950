import axios from 'axios'

export async function getReleasesByGameId(gameId) {
  return (await axios.get(`/portal/releases/?game=${gameId}`)).data
}

export async function getRelease(releaseId) {
  return (await axios.get(`/portal/releases/${releaseId}/`)).data
}

export async function getReleaseStage(id) {
  return (await axios.get(`/portal/release_stages/${id}/`)).data
}

export async function patchReleaseStage(id, serverFarmId, resourceIds) {
  return (
    await axios.patch(`/portal/release_stages/${id}/`, {
      server_farm: serverFarmId,
      resources: resourceIds,
    })
  ).data
}

export async function postRelease(game, platform, store, version) {
  return (
    await axios.post('/portal/releases/', {
      game: game.game_code,
      platform,
      store,
      version,
    })
  ).data
}

export async function requestQAForRelease(release, params) {
  return (await axios.post(`/portal/releases/${release.id}/request_qa/`, params)).data
}

export async function promoteRelease(release, params) {
  return (await axios.post(`/portal/releases/${release.id}/promote/`, params)).data
}

export async function rejectRelease(release, params) {
  return (await axios.post(`/portal/releases/${release.id}/reject/`, params)).data
}

export async function deleteRelease(release) {
  return (await axios.delete(`/portal/releases/${release.id}/`)).data
}

export async function postReleaseAttachment({ release, file }) {
  const form = new FormData()
  form.append('release', release)
  form.append('file', file)
  return (await axios.post('/portal/release_attachments/', form)).data
}
