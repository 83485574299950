import localforage from 'localforage'

export default {
  actions: {
    startLoading({ commit }) {
      commit('startLoading')
    },
    doneLoading({ commit }) {
      commit('doneLoading')
    },
    hideLoading({ commit }) {
      commit('hideLoading')
    },
    async loadFavoriteMenus({ commit }) {
      const menus = (await localforage.getItem('favorite-menus')) || []
      commit('setFavoriteMenus', menus)
    },
    async setFavoriteMenus({ commit }, menus) {
      await localforage.setItem('favorite-menus', menus)
      commit('setFavoriteMenus', menus)
    },
  },
  state: {
    loadingCount: 0,
    favoriteMenus: [],
    hideSidebar: false,
  },
  getters: {
    loading: state => state.loadingCount > 0,
  },
  mutations: {
    hideLoading(state) {
      state.loadingCount = 0
    },
    startLoading(state) {
      state.loadingCount++
    },
    doneLoading(state) {
      if (state.loadingCount > 0) {
        state.loadingCount--
      } else {
        state.loadingCount = 0
      }
    },
    setFavoriteMenus(state, menus) {
      state.favoriteMenus = menus
    },
    toggleSidebar(state) {
      state.hideSidebar = !state.hideSidebar
    },
    setHideSidebar(state, value) {
      state.hideSidebar = value
    },
  },
}
