export const DISTRIBUTION = 'distribution'
export const INQUERY = 'inquery'
export const OPERATION = 'operation'

export const MenuGroups = {
  DISTRIBUTION,
  INQUERY,
  OPERATION,
}

export default MenuGroups
