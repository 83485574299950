import moment from 'moment-timezone'

export class AnalyticsReportParam {
  constructor(obj) {
    this.label = obj.label || ''
    this.default = obj.default
    this.description = obj.description || ''
    this.type = obj.type || 'select'
    this.invisible = obj.invisible
  }

  static build(param) {
    switch (param.type) {
      case 'select':
        return new AnalyticsReportParamSelect(param)
      case 'multiselect':
        return new AnalyticsReportParamMultiselect(param)
      case 'text':
        return new AnalyticsReportText(param)
      case 'date':
        return new AnalyticsReportDate(param)
      default:
        throw Error('유효하지 않은 param입니다.')
    }
  }
}

class AnalyticsReportInput {
  constructor(param) {
    this.label = param.label
    this.description = param.description
    this.type = param.type
    this.invisible = param.invisible
  }

  get value() {
    return this._value
  }

  set value(v) {
    this._value = v
  }

  get valueString() {
    return this.value
  }
}

class AnalyticsReportParamSelect extends AnalyticsReportParam {
  constructor(param) {
    super(param)
    this.options = param.options
    this.option_labels = param.option_labels
  }

  buildInput() {
    return new AnalyticsReportSelectInput(this)
  }
}

class AnalyticsReportSelectInput extends AnalyticsReportInput {
  constructor(param) {
    super(param)
    this.default = param.default || ''
    this._value = param.default || ''
    this.options = (param.options || []).slice()
    this.option_labels = {}
    Object.assign(this.option_labels, param.option_labels || {})
  }
}

class AnalyticsReportParamMultiselect extends AnalyticsReportParam {
  constructor(param) {
    super(param)
    this.options = param.options
    this.option_labels = param.option_labels
  }

  buildInput() {
    return new AnalyticsReportMultiselectInput(this)
  }
}

class AnalyticsReportMultiselectInput extends AnalyticsReportInput {
  constructor(param) {
    super(param)
    this.default = param.default || ['Select/Deselect All']
    if (!Array.isArray(param.default)) {
      this._value = [].concat(param.options)
    } else {
      this._value = param.default.slice()
    }
    this.options = ['Select/Deselect All'].concat(param.options || [])
    this.option_labels = {}
    Object.assign(this.option_labels, param.option_labels || {})
  }

  get value() {
    return this._value
  }

  set value(v) {
    if (v.includes('Select/Deselect All')) {
      if (v.length === this.options.length) {
        this._value = []
      } else {
        this._value = this.options.slice(1)
      }
    } else {
      this._value = v
    }
  }

  get valueString() {
    if (this._value.includes('Select/Deselect All')) {
      return 'all'
    }

    // When all values are selected
    if (this._value.length === this.options.length - 1) {
      return 'all'
    }

    return this._value.join(',')
  }
}

class AnalyticsReportText extends AnalyticsReportParam {
  buildInput() {
    return new AnalyticsReportTextInput(this)
  }
}

class AnalyticsReportTextInput extends AnalyticsReportInput {
  constructor(param) {
    super(param)
    this.default = param.default || ''
    this._value = param.default || ''
  }
}

class AnalyticsReportDate extends AnalyticsReportParam {
  buildInput() {
    return new AnalyticsReportDateInput(this)
  }
}

class AnalyticsReportDateInput extends AnalyticsReportInput {
  constructor(param) {
    super(param)
    this.default = param.default || 0
    if (typeof param.default === 'number') {
      let day = new Date()
      day.setDate(day.getDate() - this.default)
      this._value = day
    } else {
      this._value = new Date(this.default)
    }
  }

  get valueString() {
    return moment(this._value).format('YYYY-MM-DD')
  }
}
