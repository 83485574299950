import * as api from '../api'
import localforage from 'localforage'

export default {
  state: {
    gameCode: null,
    gameSummaries: [],
    games: [],
  },
  actions: {
    async loadGameCode({ commit }) {
      const gameCode = await localforage.getItem('gamecode')
      commit('setGameCode', gameCode)
    },
    async saveGameCode({ commit }, gameCode) {
      await localforage.setItem('gamecode', gameCode)
      commit('setGameCode', gameCode)
    },
    async getGameSummaries({ commit }) {
      commit('setGameSummaries', await api.getGameSummaries())
    },
    async getGame({ commit }, { gameCode }) {
      const newGame = await api.getGame(gameCode)
      commit('setGame', { gameCode, newGame })
    },
    async postTerms({ commit }, { game, newTerms, languageCode }) {
      const terms = await api.postTerms(game.id, newTerms, languageCode)
      commit('postTerms', { game, terms, languageCode })
    },
    async postPrivacy({ commit }, { game, newPrivacy, languageCode }) {
      const privacy = await api.postPrivacy(game.id, newPrivacy, languageCode)
      commit('postPrivacy', { game, privacy, languageCode })
    },
    async postEUTerms({ commit }, { game, newEUTerms, languageCode }) {
      const euTerms = await api.postEUTerms(game.id, newEUTerms, languageCode)
      commit('postEUTerms', { game, euTerms, languageCode })
    },
    async queryDevPlayUser({ commit }, { game, env, queryType, queryId }) {
      const gameCode = game.game_code
      const devPlayUser = await api.getDevPlayUser(env, gameCode, queryType, queryId)
      commit('getDevPlayUser', { gameCode, devPlayUser })
    },
  },
  mutations: {
    setGameCode(state, gameCode) {
      state.gameCode = gameCode
    },
    setGameSummaries(state, gameSummaries) {
      state.gameSummaries = gameSummaries
    },
    setGame(state, { gameCode, newGame }) {
      const game = state.games.find(g => g.game_code === gameCode)
      const index = state.games.indexOf(game)
      if (index === -1) {
        state.games.push(newGame)
      } else {
        state.games.splice(index, 1, newGame)
      }
    },
    updateGame(state, game) {
      const gameIndex = state.games.findIndex(g => g.id === game.id)
      state.games.splice(gameIndex, 1, game)
    },
    postTerms(state, { game, terms, languageCode }) {
      let changed = false
      for (const i in game.terms) {
        if (game.terms[i].language_code === languageCode) {
          game.terms.splice(i, 1, terms)
          changed = true
          break
        }
      }
      if (!changed) {
        game.terms.push(terms)
      }
    },
    postPrivacy(state, { game, privacy, languageCode }) {
      let changed = false
      for (const i in game.privacy) {
        if (game.privacy[i].language_code === languageCode) {
          game.privacy.splice(i, 1, privacy)
          changed = true
          break
        }
      }
      if (!changed) {
        game.privacy.push(privacy)
      }
    },
    postEUTerms(state, { game, euTerms, languageCode }) {
      let changed = false
      for (const i in game.eu_terms) {
        if (game.eu_terms[i].language_code === languageCode) {
          game.eu_terms.splice(i, 1, euTerms)
          changed = true
          break
        }
      }
      if (!changed) {
        game.eu_terms.push(euTerms)
      }
    },
    getDevPlayUser(state, { gameCode, devPlayUser }) {
      const game = state.games.find(g => g.game_code === gameCode)
      game['devplay_user'] = devPlayUser
    },
  },
  getters: {
    getGameByGameCode: state => gameCode => {
      return state.games.find(g => g.game_code === gameCode)
    },
    getGameSummaryByGameCode: state => gameCode => {
      return state.gameSummaries.find(g => g.game_code === gameCode)
    },
  },
}
