const defaultProduct = () =>
  Object.assign({
    id: null,
    platform: 'appstore',
    identifier: '',
    name: '',
    usdPrice: '',
  })

const defaultServiceKey = () =>
  Object.assign({
    serviceKeyType: 'iab_pub',
    key: '',
  })

const defaultGame = () =>
  Object.assign({
    name: '',
    gameCode: '',
    androidBundleId: '',
    iosBundleId: '',
    googleClientId: '',
  })

const defaultServerImage = () =>
  Object.assign({
    type: '',
    version: '',
    description: '',
  })

const defaultServerLaunchConfiguration = () =>
  Object.assign({
    progress: 0,
  })

const defaultCGPMission = () =>
  Object.assign({
    missionId: 0,
    title: '',
    stampImage: null,
    stampOrder: 0,
    originalStampImage: null,
  })

const defaultEndpoint = () =>
  Object.assign({
    appEnv: 'dev',
    type: 'mid',
    env: 'qa',
    mid: '',
    deviceId: '',
    deviceIdLabel: '',
    ipAddress: '',
    platform: 'android',
    store: 'unknown',
    version: '',
    gameEndpoints: [],
    serverFarm: undefined,
    resources: [],
    remoteLoggingEndpoint: '',
    release: undefined,
    isInReview: false,
  })

export default {
  actions: {
    setCreateBox({ commit }, payload) {
      commit('setCreateBox', payload)
    },
  },
  state: {
    type: null,
    isNew: true,
    product: defaultProduct(),
    serviceKey: defaultServiceKey(),
    game: defaultGame(),
    cgpMission: defaultCGPMission(),
    endpoint: defaultEndpoint(),
  },
  mutations: {
    setCreateBox(state, params) {
      state.type = params.type
      if (params.hasOwnProperty('isNew')) {
        state.isNew = params.isNew
        if (state.isNew) {
          state.product = defaultProduct()
          state.serviceKey = defaultServiceKey()
          state.cgpMission = defaultCGPMission()
          state.endpoint = defaultEndpoint()
        }
      }
      switch (params.type) {
        case 'product': {
          if (params.hasOwnProperty('id')) {
            state.product.id = params.id
          }
          if (params.platform) {
            state.product.platform = params.platform
          }
          if (params.name) {
            state.product.name = params.name
          }
          if (params.identifier) {
            state.product.identifier = params.identifier
          }
          if (params.hasOwnProperty('usdPrice')) {
            state.product.usdPrice = Number(params.usdPrice)
          } else if (params.hasOwnProperty('usd_price')) {
            state.product.usdPrice = Number(params.usd_price)
          }
          break
        }
        case 'cgp_mission': {
          if (params.hasOwnProperty('id')) {
            state.cgpMission.id = params.id
          }
          if (params.hasOwnProperty('missionId')) {
            state.cgpMission.missionId = params.missionId
          }
          if (params.hasOwnProperty('title')) {
            state.cgpMission.title = params.title
          }
          if (params.hasOwnProperty('stampImage')) {
            state.cgpMission.stampImage = params.stampImage
          }
          if (params.hasOwnProperty('stampOrder')) {
            state.cgpMission.stampOrder = params.stampOrder
          }
          if (params.hasOwnProperty('originalStampImage')) {
            state.cgpMission.originalStampImage = params.originalStampImage
          }
          break
        }
        case 'endpoint': {
          if (params.hasOwnProperty('appEnv')) {
            state.endpoint.appEnv = params.appEnv
          }
          if (params.hasOwnProperty('_type')) {
            state.endpoint.type = params._type
          }
          if (params.hasOwnProperty('env')) {
            state.endpoint.env = params.env
          }
          if (params.hasOwnProperty('platform')) {
            state.endpoint.platform = params.platform
          }
          if (params.hasOwnProperty('store')) {
            state.endpoint.store = params.store
          }
          if (params.hasOwnProperty('version')) {
            state.endpoint.version = params.version
          }
          if (params.hasOwnProperty('mid')) {
            state.endpoint.mid = params.mid
          }
          if (params.hasOwnProperty('deviceId')) {
            state.endpoint.deviceId = params.deviceId
          }
          if (params.hasOwnProperty('deviceIdLabel')) {
            state.endpoint.deviceIdLabel = params.deviceIdLabel
          }
          if (params.hasOwnProperty('ipAddress')) {
            state.endpoint.ipAddress = params.ipAddress
          }
          if (params.hasOwnProperty('gameEndpoints')) {
            state.endpoint.gameEndpoints = params.gameEndpoints
          }
          if (params.hasOwnProperty('serverFarm')) {
            state.endpoint.serverFarm = params.serverFarm
          }
          if (params.hasOwnProperty('resources')) {
            state.endpoint.resources = params.resources
          }
          if (params.hasOwnProperty('remoteLoggingEndpoint')) {
            state.endpoint.remoteLoggingEndpoint = params.remoteLoggingEndpoint
          }
          if (params.hasOwnProperty('release')) {
            state.endpoint.release = params.release
          }
          if (params.hasOwnProperty('isInReview')) {
            state.endpoint.isInReview = params.isInReview
          }
          break
        }
        default:
          state.product = defaultProduct()
          state.serverImage = defaultServerImage()
          state.serverLaunchConfiguration = defaultServerLaunchConfiguration()
          state.endpoint = defaultEndpoint()
      }
    },
  },
}
