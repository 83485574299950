import * as api from '../api'

export default {
  actions: {
    async getUserInfo({ commit }) {
      const userInfo = await api.getUserInfo()
      commit('setUserInfo', userInfo)
    },
    async setUserInfo({ commit }, { userInfo }) {
      commit('setUserInfo', userInfo)
    },
  },
  state: {
    id: -1,
    email: '',
    name: '',
    company: {
      id: -1,
      name: '',
      emailDomain: '',
    },
    permissions: [],
    globalPermissions: [],
    authToken: null,
    managingCompanies: [],
  },
  mutations: {
    setUserInfo(state, params) {
      state.id = params.id
      state.email = params.email
      state.name = params.name
      state.company = {
        ...params.company,
        emailDomain: params.company.email_domain,
      }
      state.permissions = params.game_permissions
      state.globalPermissions = params.global_permissions
      state.authToken = params.auth_token
      state.managingCompanies = params.managing_companies
    },
  },
}
