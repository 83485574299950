import store from './stores/index'
import moment from 'moment-timezone'
import swal from 'sweetalert2'
import { getName as getCountryName, registerLocale } from 'i18n-iso-countries'
import countriesKo from 'i18n-iso-countries/langs/ko.json'
import { Timestamp } from 'client/api/grpc/google/protobuf/timestamp_pb'
import { Loading } from 'element-ui'

registerLocale(countriesKo)

export function prettyDatetime(dt, format = 'YYYY-MM-DD HH:mm:ss z') {
  if (!dt) {
    return null
  }
  return moment(dt).format(format)
}

export function simpleDatetime(dt) {
  if (!dt) {
    return null
  }
  return moment(dt).format('YYYY-MM-DD HH:mm')
}

export function prettyFileSize(bytes) {
  const exp = (Math.log(bytes) / Math.log(1024)) | 0
  const result = (bytes / Math.pow(1024, exp)).toFixed(1)

  return result + ' ' + (exp === 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B')
}

export function relativeTime(dt) {
  if (!dt) {
    return 'unknown'
  }
  return moment(dt).fromNow()
}

export function filename(fullPath) {
  return decodeURIComponent(fullPath).substring(fullPath.lastIndexOf('/') + 1)
}

export function ext(fullPath) {
  const fileName = filename(fullPath)
  return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
}

export function checkSealingFile(file, extension) {
  return file.sealed && ext(file.name) === extension
}

export const IMG_EXTS = ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'bmp']
export function isImage(filename) {
  return IMG_EXTS.includes(ext(filename))
}

export function hasPermission(perm, gameCode) {
  const user = store.state.user
  if (user.globalPermissions.includes(perm)) {
    return true
  }

  if (!user.permissions.hasOwnProperty(gameCode)) {
    return false
  }

  return user.permissions[gameCode].includes(perm)
}

// 주어진 length 의 크기 만큼 0-9a-zA-Z 사이의 랜덤 문자열을 반환한다
// 출처 : https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export function randomString(length) {
  return new Array(length).join().replace(/(.|$)/g, function() {
    return ((Math.random() * 36) | 0)
      .toString(36)
      [Math.random() < 0.5 ? 'toString' : 'toUpperCase']()
  })
}

export function randomCode(length, digitOnly) {
  const base = digitOnly ? 10 : 36
  return Math.random()
    .toString(base)
    .substr(2, length)
}

export async function swalAskForCode({ title, html, length, digitOnly }) {
  const code = randomCode(length, digitOnly)

  try {
    const userInput = await swal({
      title: title,
      type: 'warning',
      html: html + `<br><br>계속하려면, 다음 코드를 입력하세요: ${code}`,
      input: 'text',
      inputAttributes: { maxlength: length },
      inputClass: 'input-code',
      confirmButtonText: '계속',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: '취소',
    })

    if (userInput !== code) {
      swal({ type: 'error', text: '코드를 잘못 입력했습니다. 다시 시도해주세요.' }).catch(
        () => null,
      )
      return false
    }
    return true
  } catch (_) {
    return false
  }
}

function trim(str) {
  return str.replace(/^\s+|\s+$/gm, '')
}

export function rgbaToHex(rgba) {
  const parts = rgba.substring(rgba.indexOf('(')).split(',')
  const r = parseInt(trim(parts[0].substring(1)), 10).toString(16)
  const g = parseInt(trim(parts[1]), 10).toString(16)
  const b = parseInt(trim(parts[2]), 10).toString(16)
  const a = parseFloat(trim(parts[3].substring(0, parts[3].length - 1))).toFixed(2)

  return (
    '#' +
    (a * 255).toString(16).substring(0, 2) +
    ('00' + r).substr(-2) +
    ('00' + g).substr(-2) +
    ('00' + b).substr(-2)
  )
}

export const toastrOptions = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-bottom-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
}

export function countryFromCode(countryCode) {
  return getCountryName(countryCode, 'ko') || '알 수 없음'
}

export const countryNames = {
  KR: '대한민국',
  US: '미국',
  JP: '일본',
  TW: '대만',
  TH: '태국',
}

export const languageNames = {
  ko: '한국어',
  ja: '일본어',
  en: '영어',
  'zh-Hans': '중국어 간체',
  'zh-Hant': '중국어 번체',
  es: '스페인어',
  fr: '프랑스어',
  de: '독일어',
  ru: '러시아어',
  th: '태국어',
  pt: '포르투갈어',
}

export function capitalize(string) {
  return string
    .split('_')
    .map(token => {
      switch (token.toLowerCase()) {
        case 'ios':
          return 'iOS'
        case 'pvp':
        case 'url':
        case 'id':
          return token.toUpperCase()
      }

      return token.charAt(0).toUpperCase() + token.slice(1)
    })
    .join(' ')
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export function debounce(func, wait) {
  let timeout

  return function executedFunction() {
    const context = this
    const args = arguments

    const later = function() {
      timeout = null
      func.apply(context, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export function getSourceTranslationResult(translation) {
  if (translation.results.length === 0) return
  let result = translation.results.find(r => r.language_code === 'ko')
  if (result == null) {
    result = translation.results[0]
  }
  return result
}

export function isSubset(parent, child) {
  if (child.length === 0) {
    return false
  }
  for (const elem of child) {
    if (!parent.has(elem)) {
      return false
    }
  }
  return true
}

export const cmp = (a, b) => (a > b ? 1 : a < b ? -1 : 0)

export function inflight(f) {
  const flight = {}
  const wrapped = async function() {
    const key = [...arguments].join()
    if (!flight[key]) {
      flight[key] = f(...arguments)
      flight[key].then(() => {
        delete flight[key]
      })
    }

    return flight[key]
  }
  return wrapped
}

export function getPermissionDiff(allUsers, before, after) {
  const result = {}
  for (const i in before) {
    const beforePerm = before[i]
    const afterPerm = after[i]
    const beforeExist = {}
    const afterExist = {}

    for (const user of beforePerm.user_set) beforeExist[user] = true
    for (const user of afterPerm.user_set) afterExist[user] = true

    result[beforePerm.id] = {
      added: [],
      removed: [],
    }
    for (const user of allUsers) {
      if (beforeExist[user.key] && !afterExist[user.key]) {
        result[beforePerm.id].removed.push(user)
      }
      if (!beforeExist[user.key] && afterExist[user.key]) {
        result[beforePerm.id].added.push(user)
      }
    }
  }

  let html = ''
  for (const permissionId in result) {
    const addedLength = result[permissionId].added.length
    const removedLength = result[permissionId].removed.length

    if (addedLength > 0 || removedLength > 0) {
      const permission = before.find(p => p.id === parseInt(permissionId))
      html += `<h3>${permission.verbose_name ? permission.verbose_name : permission.name}</h3>`

      if (addedLength > 0) {
        for (const user of result[permissionId].added) {
          html += `<p class="small" style="color: #47c36a">+ ${user.label}</p>`
        }
      }
      if (removedLength > 0) {
        for (const user of result[permissionId].removed) {
          html += `<p class="small" style="color: red">- ${user.label}</p>`
        }
      }
    }
  }
  if (html) return `<div style="text-align: left">${html}</div>`
}

export function isDevsistersMember(user) {
  return (
    user &&
    (user.company.name.toLowerCase() === 'devsisters' || user.email.indexOf('devsisters.com') > -1)
  )
}

export const BuildStatus = {
  INITIAL: 'initial',
  BUILD: 'build',
  SEALING: 'sealing',
  ANALYSIS: 'analysis',
  COMPLETE: 'complete',
  SUBMITTED: 'submitted',
  ERROR: 'error',
  REJECTED: 'rejected',
  APPSTORE_ERROR: 'appstore-error',
}

export function kibanaQueryURL(gameCode, env, query = '*') {
  return (
    `https://kibana.devsisters.cloud/s/${gameCode}/app/kibana#/discover?` +
    '_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-15m,mode:quick,to:now))' +
    `&_a=(columns:!(level,type,mid,event,_source,logType),index:'${gameCode}-${env}-*',` +
    `interval:auto,query:(query_string:(analyze_wildcard:!t,query:'${query}')),sort:!(timestamp,desc))`
  )
}
export function kibanaDashboardURL(portalEnv, gameCode) {
  const postfix = portalEnv === 'prod' ? 'cloud' : 'systems'
  return `https://kibana.devsisters.${postfix}/s/${gameCode}/app/kibana#/dashboards?_g=()`
}

// confirmUser.call(this, ...)와 같은 형식으로 컴포넌트를 this에 바인딩해야 한다.
export async function confirmUser(message, title, confirmText, cancelText, type = 'warning') {
  if (!this || !this.$confirm) {
    console.warn("Bind proper component to 'this' when using confirmUser().")
    return false
  }
  try {
    const option = {
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      type: type,
    }
    if (title) {
      await this.$confirm(message, title, option)
    } else {
      await this.$confirm(message, option)
    }
    return true
  } catch (e) {
    return false
  }
}

// Protobuf Timestamp 또는 해당 JSON을 moment 형식으로 바꾼다.
export function pbTimestampToMoment(ts) {
  const { seconds, nanos } = ts.toObject ? ts.toObject() : ts
  return moment(seconds * 1000 + nanos / 1000)
}

// Moment 또는 문자열 등 호환 타입을 Protobuf Timestamp 객체로 변환한다.
export function momentToPbTimestamp(dt) {
  const ts = moment(dt).valueOf()
  const res = new Timestamp()
  res.setSeconds(Math.floor(ts / 1000))
  res.setNanos(1000 * (ts % 1000))
  return res
}

/**
 * 언어코드 목록을 받아 { 언어코드: '', 언어코드: '', } 꼴의 object를 반환한다.
 * ex) { ko: '', en: ''}
 * @param languageCodes 언어코드 배열
 * @returns 언어코드 별로 빈 문자열이 주어진 객체
 */
export function getEmptyTranslationObject(languageCodes) {
  return languageCodes.reduce((obj, languageCode) => {
    obj[languageCode] = ''
    return obj
  }, {})
}

/**
 * 언어코드 목록을 받아 [ {languageCode: 언어코드, text: ''}] 꼴의 array를 반환한다.
 * ex) [{ languageCode: 'ko', text: ''}, {languageCode: 'en', text: ''}]
 * @param languageCodes 언어코드 배열
 * @returns 번역 객체를 배열로 변형하여 반환
 */
export function getEmptyTranslationArray(languageCodes) {
  return languageCodes.map(languageCode => ({ languageCode, text: '' }))
}

/**
 * 언어코드 목록에 해당하는 번역 결과만 필터링하여 객체로 반환한다.
 * 언어코드 목록에 번역 결과가 없으면 빈 문자열로 처리한다.
 *
 * ex)
 * translations: [{languageCode: 'ko', text: '안녕'}]
 * languages: ['ko', 'en']
 * 반환: {ko: '안녕', en: ''}
 */
export function getTranslationObject(translations, languages = Object.keys(languageNames)) {
  const translationObj = getEmptyTranslationObject(languages)
  for (const { languageCode, text } of translations) {
    if (languages.includes(languageCode)) {
      translationObj[languageCode] = text
    }
  }
  return translationObj
}

/**
 * 언어코드 목록에 해당하는 번역 결과만 필터링하여 배열로 반환한다.
 * 언어코드 목록에 번역 결과가 없으면 빈 문자열로 처리한다.
 *
 * ex)
 * translations: {ko: '안녕'}
 * languages: ['ko', 'en']
 * 반환:
 * [
 *   {languageCode: 'ko', text: '안녕'},
 *   {languageCode: 'en', text: ''}
 * ]
 */
export function getTranslationArray(translations, languages = ['ko', 'en', 'ja', 'th', 'zh-Hant']) {
  const result = []
  for (const [languageCode, text] of Object.entries(translations)) {
    if (languages.includes(languageCode)) {
      result.push({ languageCode, text })
    }
  }
  return result
}

export function download(blob, filename) {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export async function withLoading(msg, cb) {
  const loader = Loading.service({ text: msg })
  try {
    await cb()
  } finally {
    loader.close()
  }
}

/**
 * 달성 조건의 배열을 받아 단일 쿼리 스트링을 반환한다.
 *
 * ex)
 * conditions: [
 *  { field: env, value: PROD, order: 1},
 *  { field: appContext.gameCode, value: sdktest, order: 2}
 * ]
 * 반환:
 * 'env:"prod" AND appContext.gameCode:"sdktest"'
 */
export function getQuery(conditions) {
  let query = `${conditions[0].field}:"${conditions[0].value}"`
  conditions.slice(1).forEach(condition => {
    query += ` AND ${condition.field}:"${condition.value}"`
  })
  return query
}

export function getLocalesWithoutDot(localeObject) {
  const result = {}
  Object.keys(localeObject).forEach(key => {
    const words = key.split('.')
    let newKey = ''
    for (const word of words) {
      newKey = newKey + word[0].toUpperCase() + word.substring(1)
    }
    result[newKey] = localeObject[key]
  })
  return result
}

export function getNormalizedEnv(env, lowercase = true, toDev = true) {
  if (env === null || env === undefined) {
    return ''
  }
  let e = env.toLowerCase()
  if (toDev && e === 'sandbox') {
    e = 'dev'
  }
  if (!toDev && e === 'dev') {
    e = 'sandbox'
  }
  if (!lowercase) {
    return e.toUpperCase()
  }
  return e
}
