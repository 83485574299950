// source: devplay/coupon/internals/coupon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var devplay_coupon_internals_coupon_resources_pb = require('../../../devplay/coupon/internals/coupon_resources_pb.js');
goog.object.extend(proto, devplay_coupon_internals_coupon_resources_pb);
goog.exportSymbol('proto.devplay.coupon.internals.BulkGetCouponCodesRequest', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.BulkGetCouponCodesResponse', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.GetCouponUsageWithEventRequest', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.GetCouponUsageWithEventResponse', null, global);
goog.exportSymbol('proto.devplay.coupon.internals.UserIdType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.BulkGetCouponCodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.BulkGetCouponCodesRequest.displayName = 'proto.devplay.coupon.internals.BulkGetCouponCodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devplay.coupon.internals.BulkGetCouponCodesResponse.repeatedFields_, null);
};
goog.inherits(proto.devplay.coupon.internals.BulkGetCouponCodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.BulkGetCouponCodesResponse.displayName = 'proto.devplay.coupon.internals.BulkGetCouponCodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.GetCouponUsageWithEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.displayName = 'proto.devplay.coupon.internals.GetCouponUsageWithEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.GetCouponUsageWithEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.displayName = 'proto.devplay.coupon.internals.GetCouponUsageWithEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.displayName = 'proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.displayName = 'proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.BulkGetCouponCodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.BulkGetCouponCodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    issueId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponCodesRequest}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.BulkGetCouponCodesRequest;
  return proto.devplay.coupon.internals.BulkGetCouponCodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.BulkGetCouponCodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponCodesRequest}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.BulkGetCouponCodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.BulkGetCouponCodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string issue_id = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest.prototype.getIssueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.BulkGetCouponCodesRequest} returns this
 */
proto.devplay.coupon.internals.BulkGetCouponCodesRequest.prototype.setIssueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.BulkGetCouponCodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.BulkGetCouponCodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponCodesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponCodesResponse}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.BulkGetCouponCodesResponse;
  return proto.devplay.coupon.internals.BulkGetCouponCodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.BulkGetCouponCodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponCodesResponse}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCouponCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.BulkGetCouponCodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.BulkGetCouponCodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string coupon_codes = 1;
 * @return {!Array<string>}
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.prototype.getCouponCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.devplay.coupon.internals.BulkGetCouponCodesResponse} returns this
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.prototype.setCouponCodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.BulkGetCouponCodesResponse} returns this
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.prototype.addCouponCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponCodesResponse} returns this
 */
proto.devplay.coupon.internals.BulkGetCouponCodesResponse.prototype.clearCouponCodesList = function() {
  return this.setCouponCodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.GetCouponUsageWithEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    couponCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventRequest}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.GetCouponUsageWithEventRequest;
  return proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.GetCouponUsageWithEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventRequest}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.GetCouponUsageWithEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string game_code = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventRequest} returns this
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string coupon_code = 2;
 * @return {string}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventRequest} returns this
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventRequest.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponUsage: (f = msg.getCouponUsage()) && devplay_coupon_internals_coupon_resources_pb.CouponUsage.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && devplay_coupon_internals_coupon_resources_pb.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.GetCouponUsageWithEventResponse;
  return proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new devplay_coupon_internals_coupon_resources_pb.CouponUsage;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.CouponUsage.deserializeBinaryFromReader);
      msg.setCouponUsage(value);
      break;
    case 2:
      var value = new devplay_coupon_internals_coupon_resources_pb.Event;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponUsage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      devplay_coupon_internals_coupon_resources_pb.CouponUsage.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      devplay_coupon_internals_coupon_resources_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional CouponUsage coupon_usage = 1;
 * @return {?proto.devplay.coupon.internals.CouponUsage}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.getCouponUsage = function() {
  return /** @type{?proto.devplay.coupon.internals.CouponUsage} */ (
    jspb.Message.getWrapperField(this, devplay_coupon_internals_coupon_resources_pb.CouponUsage, 1));
};


/**
 * @param {?proto.devplay.coupon.internals.CouponUsage|undefined} value
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse} returns this
*/
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.setCouponUsage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse} returns this
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.clearCouponUsage = function() {
  return this.setCouponUsage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.hasCouponUsage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Event event = 2;
 * @return {?proto.devplay.coupon.internals.Event}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.getEvent = function() {
  return /** @type{?proto.devplay.coupon.internals.Event} */ (
    jspb.Message.getWrapperField(this, devplay_coupon_internals_coupon_resources_pb.Event, 2));
};


/**
 * @param {?proto.devplay.coupon.internals.Event|undefined} value
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse} returns this
*/
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devplay.coupon.internals.GetCouponUsageWithEventResponse} returns this
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devplay.coupon.internals.GetCouponUsageWithEventResponse.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest;
  return proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    case 2:
      var value = /** @type {!proto.devplay.coupon.internals.UserIdType} */ (reader.readEnum());
      msg.setUserIdType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string game_code = 1;
 * @return {string}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest} returns this
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserIdType user_id_type = 2;
 * @return {!proto.devplay.coupon.internals.UserIdType}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.prototype.getUserIdType = function() {
  return /** @type {!proto.devplay.coupon.internals.UserIdType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.devplay.coupon.internals.UserIdType} value
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest} returns this
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.prototype.setUserIdType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest} returns this
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponUsagesWithEventsList: jspb.Message.toObjectList(msg.getCouponUsagesWithEventsList(),
    devplay_coupon_internals_coupon_resources_pb.CouponUsageWithEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse;
  return proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new devplay_coupon_internals_coupon_resources_pb.CouponUsageWithEvent;
      reader.readMessage(value,devplay_coupon_internals_coupon_resources_pb.CouponUsageWithEvent.deserializeBinaryFromReader);
      msg.addCouponUsagesWithEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponUsagesWithEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      devplay_coupon_internals_coupon_resources_pb.CouponUsageWithEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CouponUsageWithEvent coupon_usages_with_events = 1;
 * @return {!Array<!proto.devplay.coupon.internals.CouponUsageWithEvent>}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.prototype.getCouponUsagesWithEventsList = function() {
  return /** @type{!Array<!proto.devplay.coupon.internals.CouponUsageWithEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, devplay_coupon_internals_coupon_resources_pb.CouponUsageWithEvent, 1));
};


/**
 * @param {!Array<!proto.devplay.coupon.internals.CouponUsageWithEvent>} value
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse} returns this
*/
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.prototype.setCouponUsagesWithEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.devplay.coupon.internals.CouponUsageWithEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devplay.coupon.internals.CouponUsageWithEvent}
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.prototype.addCouponUsagesWithEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.devplay.coupon.internals.CouponUsageWithEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse} returns this
 */
proto.devplay.coupon.internals.BulkGetCouponUsagesWithEventsResponse.prototype.clearCouponUsagesWithEventsList = function() {
  return this.setCouponUsagesWithEventsList([]);
};


/**
 * @enum {number}
 */
proto.devplay.coupon.internals.UserIdType = {
  USER_ID_TYPE_UNSPECIFIED: 0,
  USER_ID_TYPE_MID: 1,
  USER_ID_TYPE_DEVPLAY_ID: 2
};

goog.object.extend(exports, proto.devplay.coupon.internals);
